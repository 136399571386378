
import User from '../../classes/user';
import { TaskFormBase } from '../components/task-form';
import { ApiTask } from '../types/task';

//[[ANALYTICS]]

//TODO: Some of these are not yet linked up ( see Asana: https://app.asana.com/0/1202120237384507/1207689394730539/f ).
//      This is pending prioritization (...not sure we'll care, basically). There are no tech blockers.
export enum GA4Event {
  CreateTask = 'create_task',
  ClaimTask = 'claim_task',
  DeferTask = 'defer_task',
  EndTask = 'end_task',
  EditTask = 'edit_task',
  EscalateTask = 'escalate_task',
  FollowUpTask = 'follow_up_task',
  MoveTask = 'move_task',
  PauseTask = 'pause_task',
  PostAttachment = 'post_attachment', //TODO: link up.
  PostFeed = 'post_feed', //TODO: link up.
  ResetTask = 'reset_task',
  StartTask = 'start_task',
  UnknownTaskAction = 'unknown_task_action'
};

export function mapTaskActionToGA4EventName(action: ActionT) {
  switch (action) {
    case 'resetTask':     return GA4Event.ResetTask;
    case 'moveTask':      return GA4Event.MoveTask;
    case 'startTask':     return GA4Event.StartTask;
    case 'pauseTask':     return GA4Event.PauseTask;
    case 'deferTask':     return GA4Event.DeferTask;
    case 'followUpTask':  return GA4Event.FollowUpTask;
    case 'claimTask':     return GA4Event.ClaimTask;
    case 'endTask':       return GA4Event.EndTask;
    case 'editTask':      return GA4Event.EditTask;

    default: {
      console.warn('received unknown task action: ' + action);
      return GA4Event.UnknownTaskAction;
    }
  }
};

export function buildGA4EventParams(brand: BrandConfig,
                                    user: User | null,
                                    originalTask?: ApiTask | null,
                                    updatedTask?: ApiTask | null,
                                    taskFormValues?: TaskFormBase) {
  const fieldNameSources: Record<string, any>[] = [];

  const brandFields: Record<string, any> =
    !brand
    ? {}
    : {
      brand_id: brand.brandId,
      company_name: brand.companyName
    };

  const userFields: Record<string, any> =
    !user
    ? {}
    : {
      role_id: user.role_id,
      role_name: user.role_name,
      title: user.title
    };

  const originalTaskFields: Record<string, any> =
    !originalTask
    ? {}
    : {
      old_overdue: originalTask.is_overdue,
      old_related_type: originalTask.related_type,
      old_status: originalTask.status,
      old_sub_type: originalTask.sub_type,
      old_type: originalTask.type      
    };

  const updatedTaskFields: Record<string, any> =
    !updatedTask
    ? {}
    : {
      new_overdue: updatedTask.is_overdue,
      new_related_type: updatedTask.related_type,
      new_status: updatedTask.status,
      new_sub_type: updatedTask.sub_type,
      new_type: updatedTask.type      
    };

  const taskFormFields: Record<string, any> =
    !taskFormValues
    ? {}
    : {
      form_status: taskFormValues.status,
      form_sub_type: taskFormValues.sub_type,
      form_type: taskFormValues.type      
    };
    
    //REMINDER: These objects are combined into one, so take care not to duplicate field names.

    fieldNameSources.push(brandFields);
    fieldNameSources.push(userFields);
    fieldNameSources.push(originalTaskFields);
    fieldNameSources.push(updatedTaskFields);
    fieldNameSources.push(taskFormFields);

    // ensure unique field names accross these objects
    const uniqueFieldNames = new Set<string>();

    fieldNameSources.forEach(sourceObject => {
      for (const k in sourceObject) {
        if (uniqueFieldNames.has(k)) {
          throw new Error('Duplicate field name in GA4 event params: ' + k);
        }
      }
    });

    return ({
      ...brandFields,
      ...userFields,
      ...originalTaskFields,
      ...updatedTaskFields,
      ...taskFormFields
    })
  };
