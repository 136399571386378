export function createBathroomsLabel(numBaths: number | undefined) {
  if (numBaths === undefined) {
    return " - baths";
  }
  if (numBaths > 1) {
    return `${numBaths} baths`;
  }
  return `${numBaths} bath`;
}
;
