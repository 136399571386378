import * as React from "react";
import { useAuth } from "../auth/firebase-context";
import { useCache } from "../../cache-provider";

type BrandGuardProps = {
  children: React.ReactNode;
};

export default function BrandGuard({ children }: BrandGuardProps) {
  const { user } = useAuth();
  const { memberOfBrand } = useCache();
  if (!user || memberOfBrand) {
    return (<>{children}</>);
  }
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <span className='icon-[fluent--tasks-app-24-filled] text-gray-400 my-10 h-24 w-24' />
        <h6 className="text-2xl text-center text-gray-500">
          {'Your account does not belong to this Ohana Portal brand.'}
        </h6>
        <br />
        <br />
        <h3 className="text-l text-center text-gray-500">
          {'Please check the URL, or contact your department manager for assistance.'}
        </h3>
      </div>
    </>
  );
}
