export const createPrimaryOptions = (userHasManagerPermission: boolean) => {
  if (userHasManagerPermission) {
    return [
      { title: "Complete", action: "endTask", description: 'End this task.' },
      { title: "Edit", action: "editTask", description: 'Edit this task.' }
    ];
  }
  return [
    { title: "Complete", action: "endTask", description: 'End this task.' },
  ];
};
