import { taskTypeOptions } from "./task/task-strings";

export const defaultTaskOrder = taskTypeOptions;
export class UserSettings {
  constructor(
    public readonly dark_mode: boolean = false,
    public readonly task_order = defaultTaskOrder
  ) {}
  createFirestorePayload() {
    return {
      dark_mode: this.dark_mode,
      task_order: this.task_order,
    };
  }
}
