// eslint-disable-next-line no-undef
const mrrEnv = (() => {
  let mrrEnvironment =
    process.env.REACT_APP_MRR_ENVIRONMENT === "mrr-production"
      ? "production"
      : process.env.REACT_APP_MRR_ENVIRONMENT === "mrr-staging"
      ? "staging"
      : "development";
  return mrrEnvironment;
})();

const environment = {
  port: process.env.PORT!,
  env: process.env.NODE_ENV!,
  mrrEnv,
};

const salesforce_api = {
  rest: process.env.REACT_APP_SF_APEX_REST_API_ENDPOINT!,
  domain: process.env.REACT_APP_SF_DOMAIN!,
  api_host: process.env.REACT_APP_API_HOST!,
  client_id: process.env.REACT_APP_API_CLIENT_ID!,
  x_api_key: process.env.REACT_APP_X_API_KEY!,
};

const api_keys = {
  sentry_dsn: process.env.REACT_APP_SENTRY_DSN!,
  synk_code: process.env.REACT_APP_SNYK_CODE!,
};

const firebase_api = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID!,
  appId: process.env.REACT_APP_FIREBASE_APPID!,
};

const firebase_notification_key =
  process.env.REACT_APP_FIREBASE_Web_Push_Certificate_Key!;

const jestUser = {
  email: process.env.REACT_APP_TEST_USER_EMAIL || "Not yet implemented in env!",
  password:
    process.env.REACT_APP_TEST_USER_PASSWORD || "Not yet implemented in env!",
};

export {
  environment,
  salesforce_api,
  api_keys,
  // logos,
  firebase_api,
  firebase_notification_key,
  jestUser,
};
