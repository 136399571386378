import * as React from "react";
import { useAuth } from "../auth/firebase-context";
import { SalesforceAuthModalTW } from "../auth/salesforce-oauth";

type SalesforceGuardProps = {
  children: React.ReactNode;
};

export default function SalesforceGuard({ children }: SalesforceGuardProps) {
  const { user } = useAuth();

  let showConnectionModal = true;

  if (user) {
    const accessToken = user.getAccess_Token();
    const refreshToken = user.getRefresh_Token();

    if (accessToken && refreshToken) {
      showConnectionModal = false;
    }
  }

  return (<>
    {children}
    {/* NOTE: It's important this modal be displayed above the children, blocking access. */}
    {showConnectionModal &&
      <SalesforceAuthModalTW open reconnect={false} loginHint={user?.username || ''} />
    }
  </>);
}
