interface AuthorizationRedirectResponse {
  access_token?: string;
  id?: string;
  token_type?: string;
  refresh_token?: string;
  issued_at?: string;
}

// This class is there to handle the redirect response from Salesforce
export default class AuthorizationResponse {
  private readonly access_token: string;

  private readonly refresh_token: string;

  private readonly id: string;

  private readonly token_type: string;

  private readonly issued_at: string;

  constructor(RedirectResponseData: AuthorizationRedirectResponse) {
    this.access_token =
      RedirectResponseData?.access_token ||
      "no access token";
    this.id =
      RedirectResponseData?.id ||
      "no id";
    this.token_type =
      RedirectResponseData?.token_type ||
      "no token type";
    this.refresh_token =
      RedirectResponseData?.refresh_token ||
      "no refresh token";
    this.issued_at =
      RedirectResponseData?.issued_at ||
      "no issued at";
  }

  getOrganization_Id() {
    return decodeURIComponent(this.id.split("/id/")[1].split("/")[0]);
  }

  getUser_Id() {
    return decodeURIComponent(this.id.split("/id/")[1].split("/")[1]);
  }

  // getSearchParams() {
  //   return this.searchParams;
  // }
  getAccess_Token() {
    return `${this.token_type} ${this.access_token}`;
  }
  getId() {
    return this.id;
  }
  getToken_Type() {
    return this.token_type;
  }
  getRefresh_Token() {
    return this.refresh_token;
  }
  getIssued_At() {
    return this.issued_at;
  }
}
