import { updateProfile } from "firebase/auth";
import { auth } from "../../app/config/firebase/app";
import isMemberOfGroup from "../../helpers/is-member-of-group";
import { UserSettings } from "../user-settings";
import AuthorizationResponse from "../auth";

export interface UserConstructor {
  alias: string;
  email: string;
  first_name: string;
  has_profile_photo: boolean;
  id: string;
  is_active: boolean;
  language: string;
  last_name: string;
  locale: string;
  name: string;
  nick_name: string;
  profile_picture_url: string;
  profile_thumbnail_url: string;
  role_id: string;
  role_name: string;
  sf_access_token: string;
  sf_refresh_token: string;
  sf_user_id: string;
  timezone: string;
  title: string;
  user_type: string;
  username: string;
  is_admin: boolean;

  // optional
  department?: string;
  mobile_phone?: string;
  phone?: string;
  sf_issued_at?: string;
  getAccess_Token?: () => string;
  getRefresh_Token?: () => string;
  user_settings: UserSettings | null;
}

export default class User {
  public readonly id: string;
  public readonly is_active: boolean;
  public readonly user_type: string;
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly role_id: string;
  public readonly role_name: string;
  public readonly name: string;
  public readonly title: string;
  public readonly email: string;
  public readonly alias: string;
  public readonly username: string;
  public readonly nick_name: string;
  public readonly timezone: string;
  public readonly has_profile_photo: boolean;
  public profile_picture_url: string;
  public profile_thumbnail_url: string;
  public readonly language: string;
  public readonly locale: string;
  public readonly is_admin: boolean;
  private sf_access_token: string;
  private sf_refresh_token: string;
  public readonly sf_user_id: string;

  // optional
  public sf_issued_at: string = "";
  public readonly department: string = "";
  public phone: string = ""; // unused in new version of this app; use "mobile_phone"
  public mobile_phone: string = "";
  public auth_provider: string | null;
  public messaging_id?: string;

  constructor(
    userData: UserConstructor,
    public user_settings: UserSettings | null = null
  ) {
    //NOTE: User is constructed in several ways, in several places. The type that
    //      comes in should be considered '<any>'. We generally receive a JSON block
    //      from the backend, but it could also come from Firebase, or be treated
    //      as a copy constructor.
    //      It's important to validate anything vital here in the constructor.
    //      Do not assume it's there just because we take an UserConstructor interface!

    this.id = userData.id;
    this.sf_user_id = userData.sf_user_id;
    this.is_active = userData.is_active;
    this.user_type = userData.user_type;
    this.first_name = userData.first_name;
    this.last_name = userData.last_name;
    this.role_id = userData.role_id;
    this.role_name = userData.role_name;
    this.name = userData.name;
    this.title = userData.title;
    this.email = userData.email;
    this.alias = userData.alias;
    this.username = userData.username;
    this.nick_name = userData.nick_name;
    this.timezone = userData.timezone;

    this.has_profile_photo = userData.has_profile_photo
      ? true
      : typeof userData.profile_picture_url === "string" &&
        userData.profile_picture_url !== "";

    this.profile_picture_url = userData.profile_picture_url;
    this.profile_thumbnail_url = userData.profile_thumbnail_url;
    this.language = userData.language;
    this.locale = userData.locale;
    this.is_admin = userData.is_admin;

    this.department =
      userData.department === undefined ? "" : userData.department;

    this.phone = userData.phone ? userData.phone : "";
    this.mobile_phone = userData.mobile_phone ? userData.mobile_phone : "";

    // sometimes these getters come on a copy-constructor

    this.sf_access_token =
      userData.getAccess_Token === undefined
        ? userData.sf_access_token
        : userData.getAccess_Token();

    this.sf_refresh_token =
      userData.getRefresh_Token === undefined
        ? userData.sf_refresh_token
        : userData.getRefresh_Token();

    this.sf_user_id = userData.sf_user_id;

    if (userData.sf_issued_at !== undefined) {
      this.sf_issued_at = userData.sf_issued_at;
    }

    this.auth_provider = null;

    this.user_settings = user_settings;
  }

  public refreshAccess_Token = async (newToken: string, issuedAt: string) => {
    this.sf_access_token = newToken;
    this.sf_issued_at = issuedAt;
  };

  public setProvider = (provider: string) => {
    this.auth_provider = provider;
    return provider;
  };

  public getProvider = () => this.auth_provider;

  public getAccess_Token = () => this.sf_access_token;

  public getRefresh_Token = () => this.sf_refresh_token;

  public isMemberOfGroup = (group: any) => {
    return isMemberOfGroup(this.id, group.user_ids);
  };

  public setMobilePhone = (phone: string) => {
    this.mobile_phone = phone;
    return this.mobile_phone;
  };

  public setPhone = (phone: string) => {
    this.phone = phone;
    return this.phone;
  };

  public updateEmail = (email: string) => {
    //NOTE: Technically we could support email change, but it's problematic due
    //      to issues w/ Firebase auth and the hooks we to sync Salesforce.
    throw new Error("Unable to update user email. [ ReadOnly ]");
  };

  public buildUserInfo = () => ({
    id: this.id,
    type: this.user_type,
    name: this.name,
    title: this.title,
    email: this.email,
    timezone: this.timezone,
    phone: this.phone,
    mobile_phone: this.mobile_phone,
    has_profile_photo: this.has_profile_photo,
    profile_picture_url: this.profile_picture_url,
    profile_thumbnail_url: this.profile_thumbnail_url,
  });

  public clearSFCredentials = () => {
    this.sf_access_token = "";
    this.sf_issued_at = "";
    this.sf_refresh_token = "";
  };

  public setSFCredentials = (params: AuthorizationResponse) => {
    this.sf_access_token = params.getAccess_Token();
    this.sf_issued_at = params.getIssued_At();
    this.sf_refresh_token = params.getRefresh_Token();
  };

  public generateSalesforceUpdatePayload = () => ({
    alias: this.alias,
    email: this.email,
    first_name: this.first_name,
    has_profile_photo: true,
    id: this.id,
    is_active: this.is_active,
    language: this.language,
    last_name: this.last_name,
    locale: this.locale,
    mobile_phone: this.mobile_phone,
    name: this.name,
    nick_name: this.nick_name,
    phone: this.phone,
    role_id: this.role_id,
    role_name: this.role_name,
    timezone: this.timezone,
    title: this.title,
    user_type: this.user_type,
    username: this.username,
  });

  public syncProfilePhoto = async () => {
    if (auth?.currentUser) {
      await updateProfile(auth?.currentUser, {
        photoURL: this.has_profile_photo
          ? this.profile_picture_url
          : this.profile_thumbnail_url,
      });
    }
  };

  public updateProfilePhoto = async (
    photoUrl: string,
    options?: Partial<{
      profile_picture_url: boolean;
      profile_thumbnail_url: boolean;
    }>
  ) => {
    if (options) {
      if (options.profile_picture_url) {
        this.profile_picture_url = photoUrl;
      }
      if (options.profile_thumbnail_url) {
        this.profile_thumbnail_url = photoUrl;
      }
    } else {
      this.profile_thumbnail_url = photoUrl;
    }
  };

  public checkIsManagementRole() {
    if (!this.role_name) {
      console.log("user rolename missing/invalid on check role");
      return false;
    }

    const userIsDirector =
      this.role_name.toLowerCase().indexOf("director") !== -1;
    const userIsManager =
      this.role_name.toLowerCase().indexOf("manager") !== -1;
    const userIsSupervisor =
      this.role_name.toLowerCase().indexOf("supervisor") !== -1;

    return userIsDirector || userIsManager || userIsSupervisor;
  }
}
