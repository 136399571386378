export function createConfirmationModalText(action: ActionT) {
  switch (action) {
    case 'moveTask':
      return "Are you sure you would like to move this task to the listing's next available day?";
    case 'resetTask':
      return 'Are you sure you would like to restart this task?';
    case 'pauseTask':
      return 'Are you sure you would like to pause this task?';
    case 'deferTask':
      return 'Are you sure you would like to defer this task?';
    case 'followUpTask':
      return 'Are you sure you would like to create a follow-up task?';
    case 'endTask':
      return 'Are you sure you would like to end this task?';
    case 'editTask':
      return 'Are you sure you would like to edit this task?';
    case 'claimTask':
      return 'Are you sure you would like to claim this task?';
    case 'startTask':
    default:
      return 'Are you sure you would like to start this task?';
  }
};
