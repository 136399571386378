// ----------------------------------------------------------------------

import { ElementType, lazy, Suspense } from "react";

const Loadable = <P extends object>(Component: ElementType) => (props: P) =>
(
  <Suspense fallback={null}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

export const TasksPage = Loadable<{ taskParam: string }>(lazy(() => import('./tasks-page')));
export const HomePage = Loadable(lazy(() => import('./home-page')));
export const ListingPage = Loadable(lazy(() => import('./listing-page')));
export const ListingsPage = Loadable(lazy(() => import('./listings-page')));
export const ReservationsPage = Loadable<{ title: string }>(lazy(() => import('./reservations-page')));
export const TaskPage = Loadable(lazy(() => import('./task-page')));
export const UnknownRoutePage = Loadable(lazy(() => import('./unknown-route-page')));
export const UserPage = Loadable(lazy(() => import('./user-page')));