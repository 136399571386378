
export function checkIsManagementRole(role?: string) {
  if (!role) {
    return false;
  }
  const userIsDirector = role.toLowerCase().indexOf("director") !== -1;
  const userIsManager = role.toLowerCase().indexOf("manager") !== -1;
  const userIsSupervisor = role.toLowerCase().indexOf("supervisor") !== -1;
  return userIsDirector || userIsManager || userIsSupervisor;
}
