import clsx from "clsx";
import { useMemo } from "react";
import * as Sentry from "@sentry/browser";

export const createTaskBedConfig = (task_id: string, king_bed_count: number, queen_bed_count: number, full_bed_count: number, single_bed_count: number) => {
  return [
    {
      id: `king_bed_count-${task_id}`,
      icon: 'K',
      count: king_bed_count,
    },
    {
      id: `queen_bed_count-${task_id}`,
      icon: 'Q',
      count: queen_bed_count,
    },
    {
      id: `full_bed_count-${task_id}`,
      icon: 'F',
      count: full_bed_count,
    },
    {
      id: `single_bed_count-${task_id}`,
      icon: 'S',
      count: single_bed_count,
    },
  ];
}

export const NumberOfBeds: React.FC<{ relatedReservation: ApiReservation; taskId: string; className?: string; }> = ({ relatedReservation, taskId, className }) => {
  const {
    king_bed_count,
    queen_bed_count,
    full_bed_count,
    single_bed_count
  } = relatedReservation;
  const bedConfig = useMemo(() => createTaskBedConfig(taskId, king_bed_count, queen_bed_count, full_bed_count, single_bed_count), [king_bed_count, queen_bed_count, full_bed_count, single_bed_count, taskId]);
  const bedsInfoExists = bedConfig.find(({ count }) => { return !!count; }) !== undefined;
  if (!bedsInfoExists) {
    Sentry.addBreadcrumb({
      category: 'ui',
      message: 'beds info missing for task: ' + taskId,
      level: 'info',
    });
    return null;
  }

  return (
    <div
      className={clsx("ring-gray-300 ring-1 rounded-xl h-fit py-0.5 px-2 flex justify-center items-center text-xs", className)}
    >
      <span className="icon-[fluent--bed-24-regular] mr-1" />
      {bedConfig.map(({ count, icon, id }) => {
        if (!count)
          return null;
        return (
          <span key={id} id={id} className='text-center whitespace-nowrap border-l pl-1 font-light'>
            {count}{icon}
          </span>
        );
      })}
    </div>
  );
};
