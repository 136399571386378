import {
  cleaningsTabFilterOptions,
  specialProjectsTabFilterOptions,
} from "../../classes/task/task-strings";
import brand from "./brand";

export type NavChild = {
  name: string;
  href: string;
  current: boolean;
};

export type NavParent = {
  name: string;
  href: string;
  current: boolean;
  icon?: string;
  children?: NavChild[];
};

export const cleansSubtypeOptions = cleaningsTabFilterOptions
  .map((subType) => subType.replace(" & ", " And "))
  .join(",");
export const specialSubtypeOptions = specialProjectsTabFilterOptions
  .map((subType) => subType.replace(" & ", " And "))
  .join(",");
const deliveryOptions = ["Drop", "Pickup"].join(",");
// categories
export const MY_TASKS = "my-tasks";
export const ALL_TASKS = "all";
export const UNASSIGNED_TASKS = "unassigned";
export const OVERDUE_TASKS = "overdue";

// filter presets
export const DROP_STRIPS_TASKS = "DropStrips";
export const GLASS_LANAI_TASKS = "GlassLanai";
export const MAINTENANCE_INSPECTION_TASKS = "MaintenanceInspections";
export const CLEANING_TASKS = "Cleanings";
export const FINAL_INSPECTION_TASKS = "FinalInspections";
export const SPECIAL_PROJECT_TASKS = "SpecialProjects";
export const DELIVERY_TASKS = "Deliveries";
export const SHUTTLE_SERVICE_TASKS = "ShuttleService";

// group membership driven
export const ENGINEERING_TASKS = "Engineering";
export const HOUSEKEEPING_TASKS = "Housekeeping";
export const GUEST_EXPERIENCE_TASKS = "GuestExperience";
export const OWNER_EXPERIENCE_TASKS = "OwnerExperience";

export enum TaskSearchKey {
  Assignee = "assignee",
  Listing = "listing",
  Sort = "sortBy",
  View = "filterBy",
  StartDate = "taskStartDate",
  EndDate = "taskEndDate",
  Status = "status",
  SubType = "subtype",
  Type = "types",
  Priority = "priority",
}
export const TasksPageParams = {
  All: "allTasks",
  My: "assignedTasks",
  Unassigned: "unassignedTasks",
  Overdue: "overdueTasks",
};

export const PATH_BRAND_TASKS = {
  MY_TASKS: "/tasks/" + MY_TASKS,
  ALL_TASKS: "/tasks/" + ALL_TASKS,

  DROP_STRIPS:
    "/tasks/" +
    DROP_STRIPS_TASKS +
    `?${TaskSearchKey.Type}=Clean&subtype=Drop %26 Strip`, // This %26 is an ampersand, i.e "Drop & Strip".
  MAINTENANCE_INSPECTION:
    "/tasks/" +
    MAINTENANCE_INSPECTION_TASKS +
    `?${TaskSearchKey.Type}=Inspection&subtype=Maintenance`,
  GLASS_LANAI:
    "/tasks/" +
    GLASS_LANAI_TASKS +
    `?${TaskSearchKey.Type}=Clean&subtype=Glass %26 Lanai`, // This %26 is an ampersand, i.e "Glass & Lanai".
  FINAL_INSPECTION:
    "/tasks/" +
    FINAL_INSPECTION_TASKS +
    `?${TaskSearchKey.Type}=Inspection&subtype=Final`,
  CLEANINGS:
    "/tasks/" +
    CLEANING_TASKS +
    `?${TaskSearchKey.Type}=Clean&subtype=` +
    encodeURIComponent(cleansSubtypeOptions),
  SPECIAL_PROJECTS:
    "/tasks/" +
    SPECIAL_PROJECT_TASKS +
    `?${TaskSearchKey.Type}=Clean&subtype=` +
    encodeURIComponent(specialSubtypeOptions),
  DELIVERIES:
    "/tasks/" +
    DELIVERY_TASKS +
    `?${TaskSearchKey.Type}=` +
    encodeURIComponent(deliveryOptions),
  SHUTTLE_SERVICE:
    "/tasks/" +
    SHUTTLE_SERVICE_TASKS +
    `?${TaskSearchKey.Type}=Shuttle Service`,

  UNASSIGNED: "/tasks/" + UNASSIGNED_TASKS,
  OVERDUE: "/tasks/" + OVERDUE_TASKS,

  ENGINEERING: "/tasks/" + ENGINEERING_TASKS,
  HOUSEKEEPING: "/tasks/" + HOUSEKEEPING_TASKS,
  GUEST_EXPERIENCE: "/tasks/" + GUEST_EXPERIENCE_TASKS,
  OWNER_EXPERIENCE: "/tasks/" + OWNER_EXPERIENCE_TASKS,
};

export const PATH_RESERVATIONS = {
  ROOT: "/reservations",
  ARRIVALS_TODAY:
    "/reservations/arrivals?hide_departures=true&hide_in_house=true",
  DEPARTURES_TODAY:
    "/reservations/departures?hide_arrivals=true&hide_in_house=true",
  OWNER_ARRIVALS:
    "/reservations/owner_arrivals?hide_departures=true&hide_in_house=true&type=Guest+of+Owner%2COwner",
  CHECK_IN_REQUESTS: "/reservations/check_in_requests?check_in_requests=true",
};

export const navigationByBrand: (brand: BrandConfig) => NavParent[] = () => {
  //NOTE: Order is important here! This sets the menu order.
  // Additions to the brand.listViews should be added here and in config.
  const NavLinks = [];
  if (brand.listTaskViews.includes(MY_TASKS)) {
    NavLinks.push({
      name: "My Tasks",
      href: PATH_BRAND_TASKS.MY_TASKS,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(ALL_TASKS)) {
    NavLinks.push({
      name: "All Tasks",
      href: PATH_BRAND_TASKS.ALL_TASKS,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(DROP_STRIPS_TASKS)) {
    NavLinks.push({
      name: "Drop/Strips",
      href: PATH_BRAND_TASKS.DROP_STRIPS,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(MAINTENANCE_INSPECTION_TASKS)) {
    NavLinks.push({
      name: "Maintenance Inspections",
      href: PATH_BRAND_TASKS.MAINTENANCE_INSPECTION,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(CLEANING_TASKS)) {
    NavLinks.push({
      name: "Cleanings",
      href: PATH_BRAND_TASKS.CLEANINGS,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(GLASS_LANAI_TASKS)) {
    NavLinks.push({
      name: "Glass & Lanai",
      href: PATH_BRAND_TASKS.GLASS_LANAI,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(FINAL_INSPECTION_TASKS)) {
    NavLinks.push({
      name: "Final Inspections",
      href: PATH_BRAND_TASKS.FINAL_INSPECTION,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(SPECIAL_PROJECT_TASKS)) {
    NavLinks.push({
      name: "Special Projects",
      href: PATH_BRAND_TASKS.SPECIAL_PROJECTS,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(DELIVERY_TASKS)) {
    NavLinks.push({
      name: "Deliveries",
      href: PATH_BRAND_TASKS.DELIVERIES,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(SHUTTLE_SERVICE_TASKS)) {
    NavLinks.push({
      name: "Shuttle Service",
      href: PATH_BRAND_TASKS.SHUTTLE_SERVICE,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(ENGINEERING_TASKS)) {
    NavLinks.push({
      name: "Engineering",
      href: PATH_BRAND_TASKS.ENGINEERING,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(HOUSEKEEPING_TASKS)) {
    NavLinks.push({
      name: "Housekeeping",
      href: PATH_BRAND_TASKS.HOUSEKEEPING,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(GUEST_EXPERIENCE_TASKS)) {
    NavLinks.push({
      name: "Guest Experience",
      href: PATH_BRAND_TASKS.GUEST_EXPERIENCE,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(OWNER_EXPERIENCE_TASKS)) {
    NavLinks.push({
      name: "Owner Experience",
      href: PATH_BRAND_TASKS.OWNER_EXPERIENCE,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(OVERDUE_TASKS)) {
    NavLinks.push({
      name: "Overdue",
      href: PATH_BRAND_TASKS.OVERDUE,
      current: false,
    });
  }
  if (brand.listTaskViews.includes(UNASSIGNED_TASKS)) {
    NavLinks.push({
      name: "Unassigned",
      href: PATH_BRAND_TASKS.UNASSIGNED,
      current: false,
    });
  }
  return [
    {
      name: "Dashboard",
      href: "/home",
      icon: "icon-[ion--home]",
      current: true,
    },
    {
      name: "Listings",
      icon: "icon-[icon-park-twotone--hotel]",
      href: "/listings",
      current: false,
    },
    {
      name: "Reservations",
      icon: "icon-[mdi--calendar]",
      href: PATH_RESERVATIONS.ROOT,
      current: false,
      children: [
        {
          name: "Daily Timeline",
          href: PATH_RESERVATIONS.ROOT,
          current: false,
        },
        {
          name: "Arrivals",
          href: PATH_RESERVATIONS.ARRIVALS_TODAY,
          current: false,
        },
        {
          name: "Departures",
          href: PATH_RESERVATIONS.DEPARTURES_TODAY,
          current: false,
        },
        {
          name: "Owner Arrivals",
          href: PATH_RESERVATIONS.OWNER_ARRIVALS,
          current: false,
        },
        {
          name: "Check In Requests",
          href: PATH_RESERVATIONS.CHECK_IN_REQUESTS,
          current: false,
        },
      ],
    },
    {
      name: "Tasks",
      icon: "icon-[clarity--tasks-outline-badged]",
      href: PATH_BRAND_TASKS.MY_TASKS,
      current: false,
      children: NavLinks,
    },
  ];
};
