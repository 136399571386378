import { createContext, PropsWithChildren, useContext, useId, useMemo, useState } from "react";
import Modal from "./modal";
import { format, isToday } from "date-fns";

const ReservationModalContext = createContext<{
  openReservationModal: ApiReservation | null;
  setOpenReservationModal(a: ApiReservation | null): void;
  reservationRefreshCounter: number;
  setReservationRefreshCounter: React.Dispatch<React.SetStateAction<number>>;
}>({
  openReservationModal: null,
  setOpenReservationModal: () => undefined,
  reservationRefreshCounter: 0,
  setReservationRefreshCounter: () => 0
});

export const useReservationModal = () => {
  const context = useContext(ReservationModalContext);
  if (!context) {
    throw new Error('Hook must be called within ReservationModalProvider.');
  }
  return context;
};

const ReservationModalProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const id = useId()
  const [openReservationModal, setOpenReservationModal] = useState<ApiReservation | null>(null);
  const [reservationRefreshCounter, setReservationRefreshCounter] = useState<number>(0);
  const values = useMemo(() => ({
    openReservationModal,
    setOpenReservationModal,
    reservationRefreshCounter,
    setReservationRefreshCounter
  }), [
    openReservationModal,
    setOpenReservationModal,
    reservationRefreshCounter,
    setReservationRefreshCounter
  ])
  return (
    <ReservationModalContext.Provider value={values}>
      <ReservationModal id={`reservation-modal-${id}`} reservation={openReservationModal} onClose={() => { setOpenReservationModal(null) }} />
      {children}
    </ReservationModalContext.Provider>
  )
};

const ReservationModal: React.FC<{ id: string, reservation: ApiReservation | null, onClose(): void }> = ({ id, reservation, onClose }) => {
  return (
    <Modal id={id} open={!!reservation} setOpen={onClose} hideButtons alwaysShowX={false}>
      <div className="absolute top-2 right-2">
        <button type="button" className="-m-2.5 p-2.5" onClick={onClose}>
          <span className="icon-[material-symbols--close] text-gray-500 dark:text-white hover:text-gray-900 w-5 h-5" />
        </button>
      </div>
      {reservation && <h4 className="text-lg font-semibold">{reservation.name}</h4>}
      {reservation && <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
        <div className="flex flex-col items-start">
          <h6 className="font-semibold">Guest</h6>
          <span>{reservation.guest_name}</span>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="font-semibold">Type</h6>
          <span>{reservation.type}</span>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="font-semibold">Property</h6>
          <span>{reservation.name_detailed}</span>
        </div>
        <div className="flex flex-col items-start">
          <h6 className="font-semibold">Status</h6>
          <span>{reservation.listing_status}</span>
        </div>
        {reservation.check_in && <div className="flex flex-col items-start">
          <h6 className="font-semibold">Check In</h6>
          <span>{format(new Date(reservation.check_in), 'M/dd/yyyy h:mm a')}</span>
        </div>}
        {reservation.check_out && <div className="flex flex-col items-start">
          <h6 className="font-semibold">Check Out</h6>
          <span>{format(new Date(reservation.check_out), 'M/dd/yyyy h:mm a')}</span>
        </div>}
        {reservation.has_check_in_requests && <div className="flex flex-col items-start">
          <h6 className="font-semibold">Check In Requests</h6>
          <ol>{reservation.check_in_requests.split(";").map((text, index) => <li key={`check_in_request-${index}`}>{text}</li>)}</ol>
        </div>}
      </div>}
    </Modal>
  )
};

export default ReservationModalProvider;