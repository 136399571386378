import { AuthError, AuthErrorCodes } from "firebase/auth";
/**
 * @param Firebase Auth error
 * @returns a human readable error string based off of Error.code.
 */
export const handleAuthError = (error: AuthError) => {
  const errorCode = error?.code;
  switch (errorCode) {
    case AuthErrorCodes.ADMIN_ONLY_OPERATION:
      return "User does not have permission to preform this operation.";
    case AuthErrorCodes.ARGUMENT_ERROR:
      return "Please provide valid arguments.";
    case AuthErrorCodes.APP_NOT_AUTHORIZED:
      return "Application has failed to authorized.";
    case AuthErrorCodes.APP_NOT_INSTALLED:
      return "Application is missing or has not been installed.";
    case AuthErrorCodes.CAPTCHA_CHECK_FAILED:
      return "Captcha failed. Please try again.";
    case AuthErrorCodes.CODE_EXPIRED:
      return "Your code has expired. Please request a new one and try again.";
    case AuthErrorCodes.CORDOVA_NOT_READY:
      return "Server is not yet ready.";
    case AuthErrorCodes.CORS_UNSUPPORTED:
      return "Cross Origin not supported.";
    case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
      return "Credential is already in use.";
    case AuthErrorCodes.CREDENTIAL_MISMATCH:
      return "Credentials do not match.";
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      return "Credentials expired. Please try again.";
    case AuthErrorCodes.DEPENDENT_SDK_INIT_BEFORE_AUTH:
      return "Failed to initailize application SDK.";
    case AuthErrorCodes.DYNAMIC_LINK_NOT_ACTIVATED:
      return "This link is inactive.";
    case AuthErrorCodes.EMAIL_CHANGE_NEEDS_VERIFICATION:
      return "Email has been changed. Please check your email for a verification link.";
    case AuthErrorCodes.EMAIL_EXISTS:
      return "Email already exists.";
    case AuthErrorCodes.EMULATOR_CONFIG_FAILED:
      return "Failed to configure emulator.";
    case AuthErrorCodes.EXPIRED_OOB_CODE:
      return "Your code has expired. Please request a new one and try again";
    case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
      return "This operation has expired. Please try again.";
    case AuthErrorCodes.INTERNAL_ERROR:
      return "There was a problem.";
    case AuthErrorCodes.INVALID_API_KEY:
      return "Please provide a valid api key.";
    case AuthErrorCodes.INVALID_APP_CREDENTIAL:
      return "Please provide valid application credentials.";
    case AuthErrorCodes.INVALID_APP_ID:
      return "Please provide a valid application id.";
    case AuthErrorCodes.INVALID_AUTH:
      return "Invalid authentication.";
    case AuthErrorCodes.INVALID_AUTH_EVENT:
      return "Failed to preform invalid authentication event.";
    case AuthErrorCodes.INVALID_CERT_HASH:
      return "Invalid certificate.";
    case AuthErrorCodes.INVALID_CODE:
      return "Invalid code provided. Please get a new code and try again.";
    case AuthErrorCodes.INVALID_CONTINUE_URI:
      return "Invalid continue url.";
    case AuthErrorCodes.INVALID_CORDOVA_CONFIGURATION:
      return "Invalid Cordova configuration.";
    case AuthErrorCodes.INVALID_CUSTOM_TOKEN:
      return "Invalid custom token. Please request a new one and try again.";
    case AuthErrorCodes.INVALID_DYNAMIC_LINK_DOMAIN:
      return "Invalid link domain.";
    case AuthErrorCodes.INVALID_EMAIL:
      return "Please provide a valid email and try again.";
    case AuthErrorCodes.INVALID_EMULATOR_SCHEME:
      return "Invalid emulator scheme.";
    case AuthErrorCodes.INVALID_IDP_RESPONSE:
      return "Invalid IDP response.";
    case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
      return "Invalid login credentials.";
    case AuthErrorCodes.INVALID_MESSAGE_PAYLOAD:
      return "Invalid message payload.";
    case AuthErrorCodes.INVALID_MFA_SESSION:
      return "Invalid multi-factor authentication session.";
    case AuthErrorCodes.INVALID_OAUTH_CLIENT_ID:
      return "Invalid authentication client id.";
    case AuthErrorCodes.INVALID_OAUTH_PROVIDER:
      return "Invalid authentication provider.";
    case AuthErrorCodes.INVALID_OOB_CODE:
      return "Invalid code. Please request another code and try again.";
    case AuthErrorCodes.INVALID_ORIGIN:
      return "Invalid origin. Please reconfigure application and restart.";
    case AuthErrorCodes.INVALID_PASSWORD:
      return "Invalid password. Please provide a different password.";
    case AuthErrorCodes.INVALID_PERSISTENCE:
      return "Invalid authentication persistence.";
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
      return "Invalid phone number. Please enter a phone number with the format +X (XXX) XXX-XXXX";
    case AuthErrorCodes.INVALID_PROVIDER_ID:
      return "Invalid provider id. Please reconfigure application and restart.";
    case AuthErrorCodes.INVALID_RECIPIENT_EMAIL:
      return "Invalid recipient email.";
    case AuthErrorCodes.INVALID_SENDER:
      return "Invalid sender. Please try again.";
    case AuthErrorCodes.INVALID_SESSION_INFO:
      return "Invalid session. Please log out and sign back in.";
    case AuthErrorCodes.INVALID_TENANT_ID:
      return "Invalid tenant id.";
    case AuthErrorCodes.MFA_INFO_NOT_FOUND:
      return "No multi-factor information avaliable.";
    case AuthErrorCodes.MFA_REQUIRED:
      return "Multi-factor Authentication is required.";
    case AuthErrorCodes.MISSING_ANDROID_PACKAGE_NAME:
      return "Missing required android package.";
    case AuthErrorCodes.MISSING_APP_CREDENTIAL:
      return "Missing application credentials.";
    case AuthErrorCodes.MISSING_AUTH_DOMAIN:
      return "Missing authentication domain.";
    case AuthErrorCodes.MISSING_CODE:
      return "Missing code. Please try again.";
    case AuthErrorCodes.MISSING_CONTINUE_URI:
      return "Missing continue uri. Please reconfigure and try again.";
    case AuthErrorCodes.MISSING_IFRAME_START:
      return "Missing IFrame information.";
    case AuthErrorCodes.MISSING_IOS_BUNDLE_ID:
      return "Mission Apple bundle id";
    case AuthErrorCodes.MISSING_OR_INVALID_NONCE:
      return "Missing or invalid nonce.";
    case AuthErrorCodes.MISSING_MFA_INFO:
      return "Missing multi-factor information.";
    case AuthErrorCodes.MISSING_MFA_SESSION:
      return "Missing a multi-factor authentication session.";
    case AuthErrorCodes.MISSING_PHONE_NUMBER:
      return "Missing phone number. Please provide one and try again.";
    case AuthErrorCodes.MISSING_SESSION_INFO:
      return "Missing session information.";
    case AuthErrorCodes.MODULE_DESTROYED:
      return "Module has been deleted.";
    case AuthErrorCodes.NEED_CONFIRMATION:
      return "Confirmation is required.";
    case AuthErrorCodes.NETWORK_REQUEST_FAILED:
      return "There was a problem with your network. Please try again.";
    case AuthErrorCodes.NULL_USER:
      return "No user found.";
    case AuthErrorCodes.NO_AUTH_EVENT:
      return "No autherization event found.";
    case AuthErrorCodes.NO_SUCH_PROVIDER:
      return "Provider does not exist or can not be found.";
    case AuthErrorCodes.OPERATION_NOT_ALLOWED:
      return "Operation is not allowed.";
    case AuthErrorCodes.OPERATION_NOT_SUPPORTED:
      return "Operation is not supported.";
    case AuthErrorCodes.POPUP_BLOCKED:
      return "Popup has been blocked or is not allowed.";
    case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      return "Authentication canceled.";
    case AuthErrorCodes.PROVIDER_ALREADY_LINKED:
      return "Provider has already been linked to user account.";
    case AuthErrorCodes.QUOTA_EXCEEDED:
      return "Quota exceeded. Please try again later.";
    case AuthErrorCodes.REDIRECT_CANCELLED_BY_USER:
      return "Redirect cancelled by user.";
    case AuthErrorCodes.REDIRECT_OPERATION_PENDING:
      return "Pending redirect operation.";
    case AuthErrorCodes.REJECTED_CREDENTIAL:
      return "Credentials rejected. Please update and try again.";
    case AuthErrorCodes.SECOND_FACTOR_ALREADY_ENROLLED:
      return "User has already enrolled.";
    case AuthErrorCodes.SECOND_FACTOR_LIMIT_EXCEEDED:
      return "Limit exceeded.";
    case AuthErrorCodes.TENANT_ID_MISMATCH:
      return "Mismatching tenant id.";
    case AuthErrorCodes.TIMEOUT:
      return "Session timeout. Please reload and try again.";
    case AuthErrorCodes.TOKEN_EXPIRED:
      return "Token has expired. Please request a new one and try again.";
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return "Too many attempts. Please try again later.";
    case AuthErrorCodes.UNAUTHORIZED_DOMAIN:
      return "Domain is not authorized.";
    case AuthErrorCodes.UNSUPPORTED_FIRST_FACTOR:
      return "Problem with unsupported first factor.";
    case AuthErrorCodes.UNSUPPORTED_PERSISTENCE:
      return "Problem with unsupported persistence.";
    case AuthErrorCodes.UNSUPPORTED_TENANT_OPERATION:
      return "Tenant operation is not supported.";
    case AuthErrorCodes.UNVERIFIED_EMAIL:
      return "Email is not verified. Please check you inbox for a verification link.";
    case AuthErrorCodes.USER_CANCELLED:
      return "User cancelled.";
    case AuthErrorCodes.USER_DELETED:
      return "User has been deleted.";
    case AuthErrorCodes.USER_DISABLED:
      return "User has been disabled.";
    case AuthErrorCodes.USER_MISMATCH:
      return "Mismatch user.";
    case AuthErrorCodes.USER_SIGNED_OUT:
      return "User has been signed out.";
    case AuthErrorCodes.WEAK_PASSWORD:
      return "Please provide a stronger password.";
    case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED:
      return "Website storage is unsupported.";
    case AuthErrorCodes.ALREADY_INITIALIZED:
      return "Authentication has already been initialized.";
    case AuthErrorCodes.RECAPTCHA_NOT_ENABLED:
      return "Recaptcha is not enabled. Please enable it and try again.";
    case AuthErrorCodes.MISSING_RECAPTCHA_TOKEN:
      return "Missing recaptcha token.";
    case AuthErrorCodes.INVALID_RECAPTCHA_TOKEN:
      return "Invalid recaptcha token.";
    case AuthErrorCodes.INVALID_RECAPTCHA_ACTION:
      return "Invalid recaptcha action.";
    case AuthErrorCodes.MISSING_CLIENT_TYPE:
      return "Missing client type.";
    case AuthErrorCodes.MISSING_RECAPTCHA_VERSION:
      return "Missing recaptcha version.";
    case AuthErrorCodes.INVALID_RECAPTCHA_VERSION:
      return "Recaptch is outdated. Our team has been notified.";
    case AuthErrorCodes.INVALID_REQ_TYPE:
      return "Invalid request type.";
    default:
      return "There was a problem. Please try again.";
  }
};
