import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthorizationResponse from "../../../classes/auth";
import { salesforce_api } from "../../config";
import { AppLoader } from "../app-loader";
import { useAuth } from "./firebase-context";

const followUpRoute = '/home';

export default function RedirectWithToken() {
  const [searchParams] = useSearchParams();
  const authAlreadyProcessed = useRef<boolean>(false) // TBD
  const { user, updateSFCredentials } = useAuth();
  const navigate = useNavigate();
  const [authError, setAuthError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) {
      //verbose && console.log('processing oauth without FB user');
      return;
    }

    if (searchParams.has("code") && !authAlreadyProcessed.current) {
      // prevent overlapping calls
      authAlreadyProcessed.current = true

      let pathSuffix = `/services/oauth2/token?client_id=${salesforce_api.client_id}`;

      const code = searchParams.get("code")!;
      pathSuffix = pathSuffix.concat(`&grant_type=authorization_code&code=${code}`);

      pathSuffix = pathSuffix.concat(`&redirect_uri=${window.location.origin}/oauth2/signup`)

      const path = salesforce_api.api_host + pathSuffix;

      const headers = new Headers();
      headers.set("Content-Type", "application/json");
      headers.set("x-api-key", salesforce_api.x_api_key);
      //TODO: headers.set("x-brand-id", brand.brandId); // ? Will this be needed here? Or will this use a separate subdomain, instead?

      const obtainSFCredentials =
        async () => {
          try {
            const oauthResponse = await fetch(path, {
              method: 'POST',
              headers: headers
            });

            if (oauthResponse.ok) {
              const { id, access_token, refresh_token, issued_at, token_type } = await oauthResponse.json();

              const auth = new AuthorizationResponse({
                access_token,
                id,
                token_type,
                refresh_token: refresh_token ? refresh_token : user?.getRefresh_Token(),
                issued_at,
              });

              await updateSFCredentials(auth);
              navigate(followUpRoute);
              return;
            }

            console.warn('refresh response is not ok: ' + oauthResponse.ok + ', status: ' + oauthResponse.status + ', statusText: ' + oauthResponse.statusText);
            setAuthError('The connection was rejected'
              + ((oauthResponse && oauthResponse.statusText)
                ? (' with "' + oauthResponse.statusText + '"')
                : '.'));
          }
          catch (e: any) {
            console.error('Failed to get SF credentials', e);
            setAuthError((e && e.message) ? e.message : 'There was a problem with the connection.');
          }
        };

      obtainSFCredentials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    //TODO: Pretty fullscreen load here, w/ our bg color (dark/light), an MRR logo, spinner, SVG animation?
    <div style={{ width: '100vw', textAlign: 'center', color: '#666', marginTop: '33vh' }}>
      {authError
        ? <>
          <p><b>Connection Failed</b></p>
          <p>{authError}</p>
          <p>
            <br />
            <br />
            <button
              className="mt-3 inline-flex justify-center rounded-md bg-primary-main px-3 py-2 text-sm font-semibold text-dark shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-secondary-main sm:col-start-1 sm:mt-0"
              onClick={() => navigate(followUpRoute)}
            >
              Try Again
            </button>
          </p>
        </>
        : <AppLoader message='Connecting Salesforce...' />
      }
    </div>
  );
}
