export const LoadingAttachments = ({ num }: { num: number }) => {
  const _base = new Array(num || 1).fill('loading-attachment')
  return <div className='flex flex-row gap-2'>
    {_base.map((id, index) => (
      <div key={`${id}-${index}`} className="flex justify-center items-center mt-1 w-14 h-14 outline outline-1 outline-gray-300 rounded-md">
        <span className="icon-[uiw--loading] animate-spin w-4 h-4 text-gray-500" />
      </div>
    ))
    }</div>
}
