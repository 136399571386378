import brand from "../config/brand";

export const AppLoader = (props: { message: string }) => {
  return (
    <div className="flex flex-col items-center justify-center mt-20">
      <img src={brand.loadingImageUrl} alt={brand.companyName} className='w-20 h-20 animate-pulse' />
      <div className="w-auto text-center text-gray-500 dark:text-gray-100 mt-8 text-lg">
        <p><b>{props.message || 'Loading...'}</b></p>
      </div>
    </div>
  )
};
