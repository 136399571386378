import React from "react";
import AuthorizationResponse from "../../../classes/auth";
import processError from "../../../utils";
import { useAuth } from "../../components/auth/firebase-context";
import { salesforce_api } from "../../config";
import brand from "../../config/brand";
import { refreshUserSFCredentials } from "../../utils/refresh-user-sf-credentials";


export interface useMakeSFRequestParams {
  endpointPath: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers?: Headers;
  contentType?: string;
  body?: any;
  hostPath?: string;
};

export type ApiError = {
  message: string,
  type: string
};

export type ApiResponse = {
  status: number,
  error?: ApiError
};

export type TaskApiResponse = ApiResponse & {
  tasks: any
};

// Every call to our Salesforce API should come through this hook, if possible.
export default function useMakeSFRequest() {
  const { user, clearSFCredentials, updateSFCredentials } = useAuth();

  // local helper callback
  const refreshSFCredentials = React.useCallback(
    async () => {
      if (!user) {
        return Promise.reject('no user');
      }

      if (!user.getRefresh_Token()) {
        return Promise.reject('no refresh token');
      }

      const auth = await refreshUserSFCredentials(user!.getRefresh_Token())
      if (!auth) {
        return false
      }
      await updateSFCredentials(auth);
      return true

    }, [user]);


  return React.useCallback(
    async (params: useMakeSFRequestParams, forcedRefreshOnly: boolean = false) => {
      if (user === null || !user.getAccess_Token()) {
        //verbose && console.warn('user not ready to make SF call');
        return {
          status: 0,
          error: { message: 'Connection with Salesforce not found.', type: 'Client error.' }
        };
      }

      try {
        if (forcedRefreshOnly) {
          const forcedRefreshResult = await refreshSFCredentials();
          if (forcedRefreshResult) {
            return { status: 200 };
          }
          return {
            status: 401,
            error: { message: 'Forced SF refresh failed.', type: 'Oauth error.' }
          };
        }

        params.contentType = params.contentType ? params.contentType : 'application/json';

        if (!params.headers) {
          params.headers = new Headers();
        }

        // params.headers.set('Authorization', user.getAccess_Token());
        params.headers.set('Content-Type', params.contentType as string);
        params.headers.set('x-api-key', salesforce_api.x_api_key);
        params.headers.set('x-brand-id', brand.brandId);

        params.hostPath = params.hostPath ? params.hostPath : salesforce_api.rest;

        const path = `${params.hostPath}${params.endpointPath}`;

        let attemptCount = 0;

        const sendFetch = async (): Promise<ApiResponse> => {
          if (attemptCount > 0) {
            if (params.headers?.has('Authorization')) {
              params.headers?.delete('Authorization');
            }
          }

          params.headers?.set('Authorization', user.getAccess_Token());

          const initialRequestResponse = await fetch(path, {
            method: params.method,
            headers: params.headers,
            ...(params.method !== 'GET' && { body: params.body }),
          });

          ++attemptCount;

          let processedAsError = false;

          if (!initialRequestResponse.ok) {
            if (initialRequestResponse.status === 401) {
              if (attemptCount <= 1) {
                const refreshResult = await refreshSFCredentials();
                if (refreshResult) {
                  // console.log('retry orignal call');
                  return await sendFetch();
                }
              }
              // else too many retries or refresh failed; either way SF connection lost

              console.warn('refresh failed; wiping SF oauth connection');
              // this situation triggers a nav-to-root w/ SF modal
              await clearSFCredentials();
              return {
                status: initialRequestResponse.status,
                error: { message: 'Connection with Salesforce denied.', type: initialRequestResponse.statusText }
              };
            }

            if (initialRequestResponse.status >= 400 && initialRequestResponse.status < 500) {
              // log 400 level errors
              console.warn('API Error', initialRequestResponse);
            }
            else if (initialRequestResponse.status >= 500) {
              // log exceptions and send to Sentry
              console.error('API Exception', initialRequestResponse);
              const failureText = await initialRequestResponse.text()
              processError(new Error(failureText), '');
              processedAsError = true;
            }
          }

          const processedResponse = await processAPIResponse(initialRequestResponse, processedAsError);
          return processedResponse;
        };

        return await sendFetch();
      } catch (e: any) {
        console.warn('Failed to complete Salesforce fetch', e);
        return Promise.reject(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );
}

// local helper; pulls json from a response w/ try
async function processAPIResponse(response: Response, processedAsError: boolean = false): Promise<ApiResponse> {
  if (processedAsError) {
    return {
      status: response.status,
      error: { message: 'Server error.', type: response.statusText }
    };
  }
  try {
    if (response.ok && response.headers.get('Content-Type') !== 'application/json') {
      return response;
    }
    const responseJSON: Response = await response.json();
    if (!responseJSON.status) {
      return {
        status: response.status,
        error: { message: 'Unhandled exception.', type: response.statusText }
      };
    }
    return responseJSON;
  }
  catch (e: any) {
    console.error('error on process API response', e);
    return {
      status: 500,
      error: { message: e.message, type: 'unknown exception' }
    };
  }
}
