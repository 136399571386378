
export function checkIsEngineer(userQueues: ApiQueue[]) {
  for (let i = 0; i < userQueues.length; ++i) {
    const entry = userQueues[i];
    if (entry.name === 'Engineering') {
      return true;
    }
  }
  return false;
}
