import { checkTaskAssigneeMembership } from "./check-task-assignee-membership";
import { checkIsManagementRole } from "./check-is-management-role";
import { checkIsEngineer } from "./check-is-engineer";
import { ApiTask } from '../types/task';
import User from "../../classes/user";

export const userHasPermission = (task: ApiTask | null, myQueues: ApiQueue[], user: User | null) => {
  if (!task || !myQueues || !user) {
    return {
      is_my_task: false,
      is_manageable_by_user: false,
      is_claimable_by_user: false
    };
  }
  const user_is_additional_housekeeper = task.additional_housekeepers ? task.additional_housekeepers.includes(user.name) : false;
  const is_my_task = user.sf_user_id === task.assignee_id || user_is_additional_housekeeper;
  const is_manageable_by_user = user.sf_user_id === task.assignee_id || checkIsManagementRole(user.role_name) || checkIsEngineer(myQueues) || task.reporter_id === user.sf_user_id;
  const is_claimable_by_user = task.is_claimable && task.status === 'Not Started' && checkTaskAssigneeMembership(task, myQueues);
  return {
    is_my_task,
    is_manageable_by_user,
    is_claimable_by_user
  };
};
