import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { salesforce_api } from "../../app/config";
import brand from "../../app/config/brand";
import AuthorizationResponse from "../../classes/auth";
import { refreshUserSFCredentials } from "../../app/utils/refresh-user-sf-credentials";

const baseHeaders = () => {
  const base = new Headers();
  base.set("x-api-key", salesforce_api.x_api_key);
  base.set("x-brand-id", brand.brandId);
  return base;
};
const baseQuery = fetchBaseQuery({
  baseUrl: "/",
  headers: baseHeaders(),
});
const cacheHoldTime = 30 * 60;
enum EndpointTags {
  Reservations = "reservations",
}
const api = createApi({
  reducerPath: "api",
  baseQuery,
  keepUnusedDataFor: cacheHoldTime, // RTKQ's default is one minute
  tagTypes: [EndpointTags.Reservations],
  endpoints: (build) => ({
    getReservations: build.query<
      ApiReservation[],
      { authToken: string; refreshToken: string; path: string }
    >({
      queryFn: async (args) => {
        const response = await getReservationsPageinationRequest({
          authToken: args.authToken,
          refreshToken: args.refreshToken,
          path: args.path,
        });
        return response;
      },
      providesTags: (res, error, args) => [
        { type: EndpointTags.Reservations, id: args.path },
      ],
    }),
  }),
});

const getReservationsPageinationRequest = async ({
  authToken,
  refreshToken,
  path,
  _page = 1,
  _reservations = [] as ApiReservation[],
}: {
  authToken: string;
  refreshToken: string | false;
  path: string;
  _page?: number;
  _reservations?: ApiReservation[];
}) => {
  const endpoint = `${salesforce_api.rest}${path}&page_number=${_page}`;
  const headers = baseHeaders();
  headers.set(
    "authorization",
    authToken
    // `${authToken}${_page === 2 && refreshToken ? "fail" : ""}` // Test fail
  );
  const response = await fetch(endpoint, {
    method: "GET",
    headers: headers,
  });
  if (!response.ok) {
    if (response.status === 401 && refreshToken) {
      try {
        const newAuth = await refreshUserSFCredentials(refreshToken);
        if (newAuth instanceof AuthorizationResponse) {
          const token = newAuth.getAccess_Token();
          await getReservationsPageinationRequest({
            authToken: token,
            refreshToken: false, // We do not pass refreshToken here to prevent infinite recursion.
            path,
            _page,
            _reservations,
          });
        } else {
          const error = new Error(
            "Failed to preform new request with updated tokens."
          );
          throw error;
        }
      } catch (e) {
        console.log(e);
        const error = new Error("Failed to refresh user credentials.");
        throw error;
      }
    } else {
      throw response;
    }
  }
  const json = await response.json();
  if (!json?.paging) {
    return { data: _reservations };
  }
  const {
    reservations,
    paging: { next_page },
  } = json;

  _reservations.push(...(reservations as ApiReservation[]));

  if (typeof next_page === "number" && next_page > 0) {
    await getReservationsPageinationRequest({
      authToken,
      refreshToken,
      path,
      _page: next_page,
      _reservations,
    });
  }

  return { data: _reservations };
};

export const { useGetReservationsQuery } = api;
export default api;
