// the member id will act as the selector in this function
type MemberId = string;
// the listOfIdsWithinGroup will contain all of the member's ids within that group
type ListOfIdsWithinGroup = MemberId[];

type IsMemberOfGroupCallback = (a: MemberId, b: ListOfIdsWithinGroup) => boolean;
/**
 *
 * @param memberId - ID of member
 * @param listOfIdsWithinGroup - list of members
 * @returns simple .includes method that returns a boolean
 */

const isMemberOfGroup: IsMemberOfGroupCallback = (memberId, listOfIdsWithinGroup) => {
  return listOfIdsWithinGroup.includes(memberId);
};

export default isMemberOfGroup;
