export function createBedroomsLabel(numBeds: number | undefined) {
  switch (numBeds) {
    case undefined:
      return "- bedrooms";
    case 0:
      return "Studio";
    case 1:
      return "1 bedroom";
    default:
      return `${numBeds} bedrooms`;
  }
}
;
