/* eslint-disable no-lone-blocks */
import clsx from "clsx";
import { ConfirmationResult, RecaptchaVerifier, signInWithCustomToken, signInWithPhoneNumber } from "firebase/auth";
import { useCallback, useEffect, useId, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { confirmOneTimePassword, ConfirmOTPResponse, emailOneTimePassword, GCFErrorResponse, getSignInMethods, verifyUser, VerifyUserResponse } from "../service/cloud-functions";
import { handleAuthError } from "../utils/handle-auth-error";
import { makeSFUsername } from "./make-sf-username";
import { FirebaseError } from "firebase/app";
import { auth } from "../config/firebase/app";
import brand from "../config/brand";

enum AuthStep {
  SubmitEmail,
  ChooseSignInMethod,
  SubmitOtp
};
export const LoginPage: React.FC<{}> = () => {
  // const [searchParams] = useSearchParams();
  // const hasDefaultEmailParam = searchParams.has('email');
  // const hasDefaultUsernameParam = searchParams.has('username');
  const [showContactDepartmentAlert, setShowContactDepartmentAlert] = useState(false);
  const [stepInLoginFlow, setStepInLoginFlow] = useState<AuthStep>(AuthStep.SubmitEmail);
  const [signInMethods, setSignInMethods] = useState<{ email: string, phone?: string, methods: string[], status: number } | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginErrors, setLoginErrors] = useState<Record<AuthStep, string>>({
    [AuthStep.SubmitEmail]: '',
    [AuthStep.ChooseSignInMethod]: '',
    [AuthStep.SubmitOtp]: ''
  })
  // Choose sign in methods
  const [verifyUserResponse, setVerifyUserResponse] = useState<VerifyUserResponse | null>(null)
  const [signInMethod, setSignInMethod] = useState<'phone' | 'email' | null>(null);
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
  useLayoutEffect(() => {
    setRecaptcha((prev) => {
      if (prev) {
        prev.clear();
      }
      return new RecaptchaVerifier(
        auth,
        document.getElementById('hidden-recaptcha-element') || 'hidden-recaptcha-element', // invisible mode requires an element present in the DOM
        {
          'size': 'invisible',
          // 'theme': theme.palette.mode || 'light',
          'callback': (response: any) => {
            // console.log('recaptcha callback passed/solved', response);
          },
          'expired-callback': () => {
            console.warn('recaptcha expired');
          },
        },
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <link id="brand-css" rel="stylesheet" href={brand.customCssUrl} />
      </Helmet>
      <div id="hidden-recaptcha-element" />
      <div id="login-page-backdrop" className="bg-primary-main h-dvh w-dvw flex items-center justify-center px-4 py-10">
        <div
          className="rounded-md w-full max-w-sm h-fit bg-white shadow-md p-8"
        >
          <div className="flex flex-col items-center my-4">
            <div className="flex-none bg-secondary-main rounded-full">
              <img src={brand.iconUrl} className='w-16 h-16 sm:w-24 sm:h-24' alt="logo" />
            </div>
            <h6 className="font-bold text-2xl sm:text-3xl mt-2">Log In</h6>
          </div>
          <div className="my-6">
            <SubmitUsernameStep
              next={(verifyUserResponse) => {
                setVerifyUserResponse(verifyUserResponse)
                setStepInLoginFlow(AuthStep.ChooseSignInMethod);
              }}
              className={clsx(stepInLoginFlow !== AuthStep.SubmitEmail && 'hidden')} />
            <ChooseSignInMethodStep
              recaptcha={recaptcha}
              setSignInMethods={setSignInMethods}
              signInMethods={signInMethods}
              verifyUsernameResponse={verifyUserResponse}
              next={(method, confirmationResult) => {
                setSignInMethod(method);
                if (method === 'email') {
                  setShowContactDepartmentAlert(true);
                } else {
                  setShowContactDepartmentAlert(false);
                }
                setStepInLoginFlow(AuthStep.SubmitOtp)
                if (confirmationResult) {
                  setConfirmationResult(confirmationResult);
                }
              }}
              back={() => {
                setStepInLoginFlow(AuthStep.SubmitEmail);
              }}
              className={clsx(stepInLoginFlow !== AuthStep.ChooseSignInMethod && 'hidden')}
            />
            <SubmitOtpStep
              showContactDepartment={showContactDepartmentAlert}
              signInMethods={signInMethods}
              recaptcha={recaptcha}
              verifyUserResponse={verifyUserResponse}
              back={() => {
                setStepInLoginFlow(AuthStep.ChooseSignInMethod);
                setSignInMethod(null);
                setConfirmationResult(null);
              }}
              method={signInMethod}
              confirmationResult={confirmationResult}
              setConfirmationResult={setConfirmationResult}
              className={clsx(stepInLoginFlow !== AuthStep.SubmitOtp && 'hidden')}
            />
          </div>
        </div>
      </div>
    </>
  )
};

const SubmitUsernameStep: React.FC<{ className?: string, inputClassName?: string, next(a: VerifyUserResponse): void }> = ({ className, inputClassName, next }) => {
  const id = useId();
  const [searchParams] = useSearchParams()
  const [submitting, setSubmitting] = useState(false);
  const [username, setUsername] = useState(searchParams.get('username') || '');
  const [error, setError] = useState<string | null>(null)
  const verifyUsernameRequest = useCallback(async () => {
    setSubmitting(true);
    const sf_username = makeSFUsername(brand, username.trim());
    if (sf_username instanceof Error) {
      setError(sf_username.message);
      setSubmitting(false);
      return
    };
    const verifyResponse = await verifyUser('', sf_username);
    if (!verifyResponse.success) {
      setError((verifyResponse as GCFErrorResponse).error.message);
      // setError('Please try again with a different email.');
      setSubmitting(false);
      return
    };
    next(verifyResponse as VerifyUserResponse);
    setSubmitting(false);
  }, [next, username]);

  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <div className="rounded-md bg-secondary-main p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <span className="icon-[material-symbols--info-outline] h-5 w-5 text-white" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-lg -mt-0.5 text-white font-bold w-full">
              {brand.greetingLong}
            </p>
            <p className="text-sm text-white">
              Please enter your username to continue.
            </p>
          </div>
        </div>
      </div>
      <form id={`login-username-form-${id}`}
        onSubmit={async (e) => {
          e.preventDefault();
          await verifyUsernameRequest();
        }}
      >
        <label htmlFor={`login-username-input-${id}`} className={clsx(error ? "text-error-dark" : "text-gray-500")}>Username</label>
        <input
          maxLength={80}
          id={`login-username-input-${id}`}
          autoComplete="username"
          className={clsx('leading-6 rounded-md w-full focus:ring-1',
            !error ? 'hover:ring-secondary-main focus:ring-secondary-main border-primary-main hover:border-secondary-main focus:border-secondary-main'
              : 'border-error-dark hover:ring-error-dark focus:ring-error-dark hover:border-error-dark focus:border-error-dark',
            inputClassName)}
          value={username}
          onChange={(e) => {
            setError(null)
            const val = e.currentTarget.value;
            setUsername(val);
            if (!val) {
              setError('Please provide a username.');
            }
          }}
          onInput={(e) => {
            setError(null)
            const val = e.currentTarget.value;
            setUsername(val);
            if (!val) {
              setError('Please provide a username.');
            }
          }}
        />
        {error && <span className="text-error-dark text-sm">{error}</span>}
        <div className="flex justify-center w-full mt-4">
          <button type="submit" disabled={submitting} className="mt-2 rounded-md bg-primary-main text-white font-semibold px-2 py-1.5 min-w-24 disabled:bg-gray-500 w-full hover:bg-secondary-main">
            {!submitting ? 'Continue' : <span className="icon-[uiw--loading] animate-spin" />}
          </button>
        </div>
      </form>
    </div>
  )
};

const ChooseSignInMethodStep: React.FC<{
  verifyUsernameResponse: VerifyUserResponse | null,
  next(
    method: 'phone' | 'email',
    confirmationResult?: ConfirmationResult,
  ): void,
  signInMethods: {
    email: string;
    phone?: string | undefined;
    methods: string[];
    status: number;
  } | null,
  setSignInMethods(a: {
    email: string;
    phone?: string | undefined;
    methods: string[];
    status: number;
  } | null): void,
  back(): void,
  className?: string,
  recaptcha:
  RecaptchaVerifier | null,
}> = ({ verifyUsernameResponse, next, back, className, recaptcha, signInMethods, setSignInMethods }) => {
  const [error, setError] = useState<string | null>(null)
  const [loadingEmailSent, setLoadingEmailSent] = useState(false);
  const [loadingTextSent, setLoadingTextSent] = useState(false);
  // const [signInMethodsError, setSignInMethodsError] = useState<string | null>(null);
  const [loadingSignInMethods, setLoadingSignInMethods] = useState(false);
  useEffect(() => {
    if (verifyUsernameResponse?.success) {
      setLoadingSignInMethods(true);
      setError(null);
      getSignInMethods(verifyUsernameResponse.sfUser.email).then((methods) => {
        if (!methods.success) {
          setError('Failed to get sign-in methods.');
          return;
        };
        setSignInMethods((methods as any).signInMethods);
      }).catch(() => setError('There was a problem getting sign-in methods.'))
        .finally(() => setLoadingSignInMethods(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyUsernameResponse]);
  if (!verifyUsernameResponse || !verifyUsernameResponse.success) {
    return (
      <div className={clsx("rounded-md bg-error-dark p-4", className)}>
        <div className="flex">
          <div className="flex-shrink-0">
            <span className="icon-[material-symbols--info-outline] h-5 w-5 text-white" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-white">There was a problem. Please re-try the last step.</p>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={clsx("flex flex-col w-full", className)}>
      <div className="rounded-md bg-secondary-main p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <span className="icon-[material-symbols--info-outline] h-5 w-5 text-white" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm text-white">To continue, please select a verification method.</p>
            <p className="text-sm text-white">You will be sent a 6-digit code for login.</p>
          </div>
        </div>
      </div>
      {loadingSignInMethods &&
        <div className={clsx(error ? 'ring-error-dark divide-error-dark' : 'ring-primary-main divide-primary-main', "flex flex-col ring-1 rounded-md divide-y mt-4 animate-fade-in")}>
          <button
            type="button"
            disabled
            className={
              clsx("flex items-center hover:bg-gray-200 w-full h-12 leading-8 text-left px-4 font-medium")}
            key='email-provider-loading'
            onClick={() => { }}
          >
            <span className="icon-[uiw--loading] animate-spin mr-1 w-6 h-6" />
            <span className="text-inherit">Loading</span>
          </button>
        </div>}
      {!loadingSignInMethods &&
        <div className={clsx(error ? 'ring-error-dark divide-error-dark' : 'ring-primary-main divide-primary-main', "flex flex-col ring-1 rounded-md divide-y mt-4 animate-fade-in")}>
          {signInMethods && signInMethods.methods.map((provider, index: number) => {
            const first = index === 0
            const last = index === signInMethods.methods.length - 1
            switch (provider) {
              // case 'email':
              //   return (
              //     <button
              //       type="button"
              //       disabled={loadingEmailSent || loadingTextSent}
              //       className={
              //         clsx("flex items-center hover:bg-gray-200 w-full h-12 leading-8 text-left px-4 font-medium",
              //           first && 'rounded-t-md -pb-px',
              //           last && 'rounded-b-md -pt-px')}
              //       key={`email-provider-${index}`}
              //       onClick={async () => {
              //         setError(null);
              //         setLoadingEmailSent(true)
              //         const emailSent = await emailOneTimePassword(signInMethods.email);
              //         if (!emailSent.success) {
              //           setError((emailSent as GCFErrorResponse).error.message);
              //           setLoadingEmailSent(false);
              //           return;
              //         }
              //         next('email');
              //         setLoadingEmailSent(false);
              //       }}
              //     >
              //       {loadingEmailSent ? <span className="icon-[uiw--loading] animate-spin mr-1 w-6 h-6" /> : <span className="icon-[ic--outline-email] mr-1 w-6 h-6" />}
              //       <span className="text-inherit">Email code to {signInMethods.email.slice(0, 4)}••••••</span>
              //     </button>
              //   );
              case 'phone':
                return <button
                  type="button"
                  disabled={loadingEmailSent || loadingTextSent}
                  className={
                    clsx("flex items-center hover:bg-gray-200 w-full h-12 leading-8 text-left px-4 font-medium",
                      first && 'rounded-t-md -pb-px',
                      last && 'rounded-b-md -pt-px',)}
                  key={`phone-provider-${index}`}
                  onClick={async () => {
                    if (recaptcha) {
                      setError(null)
                      setLoadingTextSent(true);
                      const confirmationResult =
                        await signInWithPhoneNumber(auth, signInMethods.phone!, recaptcha)
                          .then((cR) => {
                            return cR;
                          })
                          .catch((e: any) => {
                            console.error('sign in with phone error', e);
                            setError(handleAuthError(e));
                            return null;
                          })
                          .finally(() => {
                            setLoadingTextSent(false);
                          });

                      if (confirmationResult === null) {
                        return;
                      }

                      next('phone', confirmationResult);
                    } else {
                      setError('Please try another method.')
                    }
                  }}>
                  {loadingTextSent ? <span className="icon-[uiw--loading] animate-spin mr-1 w-6 h-6" /> : <span className="icon-[ph--phone] mr-1 w-6 h-6" />}
                  <span className="text-inherit">Text code to ••••••{signInMethods.phone!.slice(-4)}</span>
                </button>;
              default:
                return null
            }
          })}
        </div>
      }
      {error && <span className="text-error-dark text-sm">{error}</span>}
      <p className="text-sm text-gray-500 mt-3 text-center">Having trouble receiving a {' '}
        <button
          disabled={loadingEmailSent}
          className="text-primary-main hover:text-secondary-main underline"
          onClick={
            async () => {
              if (!signInMethods) {
                setError('Please try again');
                return;
              }
              setError(null);
              setLoadingEmailSent(true)
              const emailSent = await emailOneTimePassword(signInMethods.email);
              if (!emailSent.success) {
                setError((emailSent as GCFErrorResponse).error.message);
                setLoadingEmailSent(false);
                return;
              }
              next('email');
              setLoadingEmailSent(false);
            }
          }>code</button>?
        {loadingEmailSent && <span className="icon-[uiw--loading] animate-spin w-3 h-3" />}
      </p>
      <div className="flex w-full mt-4">
        <button type="button" onClick={back}>Back</button>
      </div>
    </div>
  )
};

const SubmitOtpStep: React.FC<{
  method: 'email' | 'phone' | null,
  signInMethods: {
    email: string;
    phone?: string | undefined;
    methods: string[];
    status: number;
  } | null,
  confirmationResult: ConfirmationResult | null,
  setConfirmationResult(a: ConfirmationResult | null): void,
  verifyUserResponse: VerifyUserResponse | null,
  back(): void,
  recaptcha: RecaptchaVerifier | null,
  showContactDepartment: boolean,
  className?: string,
}> = ({ showContactDepartment, method, confirmationResult, signInMethods, setConfirmationResult, className, verifyUserResponse, back, recaptcha }) => {
  const id = useId();
  const [submitting, setSubmitting] = useState(false);
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [resendingCode, setResendingCode] = useState(false);
  const [showResendNotice, setShowResendNotice] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(async (otpCode: string[]) => {
    if (!isSixDigitNumber(otpCode.join(''))) {
      setError('This is not a valid 6-digit number.')
      return;
    }
    setError(null);
    setSubmitting(true);
    if (method === 'phone') {
      if (!confirmationResult) {
        setError('Unable to confirm phone code. Please select another method.')
      }
      else {
        await confirmationResult.confirm(otpCode.join('')).catch(() => {
          setError('The entered 6-digit code is invalid. Please try again.')
        })
      }
    }
    else if (method === 'email') {
      if (verifyUserResponse !== null) {
        const response = await confirmOneTimePassword(verifyUserResponse.sfUser.email, otpCode.join(''))
        if (!response.success) {
          setError('The entered 6-digit code is invalid. Please try again.')
        }
        if (!(response as ConfirmOTPResponse).confirmed) {
          setError((response as ConfirmOTPResponse).message);
        }
        else {
          await signInWithCustomToken(auth, (response as any).customToken).then(() => {
          }).catch((error) => {
            console.error('sign in error', error);
            setError('Sign-in failed. Please try again.');
          });
        }
      }
      else {
        setError('Unable to confirm email code. Please select another method.')
      }
    }

    setSubmitting(false);
  }, [confirmationResult, method, verifyUserResponse])

  if (signInMethods === null || method === null || (method === 'phone' && confirmationResult === null) || verifyUserResponse === null) {
    return null;
  }
  const infoSectionText = method === 'phone' ? `A 6-digit code has been sent to your phone ending in ••••••${signInMethods.phone!.slice(-4)}. Please enter it here to login.` : `A 6-digit code has been sent to your email ${signInMethods.email.slice(0, 4)}••••••. Please enter it here to login.`
  return (
    <div className={clsx("", className)}>
      {showContactDepartment ?
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <span className="icon-[heroicons-outline--exclamation] h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Contact your department manager.</h3>
              <div className="mt-1 text-sm text-yellow-700">
                <p>
                  A code has been sent to your department manager.
                </p>
              </div>
            </div>
          </div>
        </div>
        : <div className="rounded-md bg-secondary-main p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <span className="icon-[material-symbols--info-outline] h-5 w-5 text-white" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-white">{infoSectionText}</p>
            </div>
          </div>
        </div>}
      <div className="animate-fade-in">
        <div className="mt-4">
          <form
            id={`otp-validation-form-${id}`}
            onSubmit={async (e) => {
              e.preventDefault()
              await handleSubmit(code);
            }}
            onPaste={async (event) => {
              event.preventDefault()
              setError(null)
              const text = event.clipboardData.getData('text').replace(/\D/g, '');
              const textArray = text.trim().split('');
              const newCode = ['', '', '', '', '', ''];
              let index = 0;
              while (index < textArray.length && index < newCode.length) {
                newCode[index] = textArray[index];
                index += 1
              }
              setCode(newCode);
              await handleSubmit(newCode);
            }}
            onInput={async (e) => {
              e.preventDefault();
              setError(null);
              const formValues = new FormData(e.currentTarget);
              const values = Array.from(formValues.values()) as string[];//CodeArr;
              setCode(values);
              let nextOpen = values.findIndex((val: string) => val === '');
              if (nextOpen > -1) {
                nextOpen += 1;
              }
              document.getElementById(`validation-input-${Math.min(6, nextOpen)}`)?.focus({ preventScroll: true });
              if (values.join('').length === 6) {
                console.log('requesting submit input');
                await handleSubmit(values);
              }
            }}
          >
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col">
                <div className="flex flex-row justify-between px-1">

                  {/* CODE SLOT 1/6 */}
                  <div className="w-10 h-10 sm:w-12 sm:h-12 peer">
                    <input
                      disabled={submitting}
                      onKeyUp={({ key }) => {
                        if (key === 'Backspace') {
                          return;
                        }
                        document.getElementById(`validation-input-2`)?.focus();
                      }}
                      inputMode="numeric"
                      tabIndex={1}
                      max={1}
                      maxLength={1}
                      value={code[0]}
                      onChange={(e) => {
                        setError(null);
                        const val = e.currentTarget ? e.currentTarget.value : '';
                        setCode((prev) => replaceValueAtIndex(prev, 0, val));
                      }}
                      className={clsx(error && '!border-error-dark !focus:ring-error-dark !focus:border-error-dark !text-error-dark', "disabled:text-gray-500 w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-main focus:border-primary-main")}
                      type="text"
                      name="validation-input-1"
                      id="validation-input-1" />
                  </div>

                  {/* CODE SLOT 2/6 */}
                  <div className="w-10 h-10 sm:w-12 sm:h-12 peer">
                    <input
                      disabled={submitting}
                      onKeyUp={({ key }) => {
                        if (key === 'Backspace') {
                          document.getElementById(`validation-input-1`)?.focus();
                          return;
                        }
                      }}
                      inputMode="numeric"
                      tabIndex={2}
                      max={1}
                      maxLength={1}
                      value={code[1]}
                      onChange={(e) => {
                        setError(null);
                        const val = e.currentTarget ? e.currentTarget.value : '';
                        setCode((prev) => replaceValueAtIndex(prev, 1, val));
                      }}
                      className={clsx(error && '!border-error-dark !focus:ring-error-dark !focus:border-error-dark !text-error-dark', "disabled:text-gray-500 w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-main focus:border-primary-main")}
                      type="text"
                      name="validation-input-2"
                      id="validation-input-2" />
                  </div>

                  {/* CODE SLOT 3/6 */}
                  <div className="w-10 h-10 sm:w-12 sm:h-12 peer">
                    <input
                      disabled={submitting}
                      onKeyUp={({ key }) => {
                        if (key === 'Backspace') {
                          document.getElementById(`validation-input-2`)?.focus();
                          return;
                        }
                      }}
                      inputMode="numeric"
                      tabIndex={3}
                      max={1}
                      maxLength={1}
                      value={code[2]}
                      onChange={(e) => {
                        setError(null);
                        const val = e.currentTarget ? e.currentTarget.value : '';
                        setCode((prev) => replaceValueAtIndex(prev, 2, val));
                      }}
                      className={clsx(error && '!border-error-dark !focus:ring-error-dark !focus:border-error-dark !text-error-dark', "disabled:text-gray-500 w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-main focus:border-primary-main")}
                      type="text"
                      name="validation-input-3"
                      id="validation-input-3" />
                  </div>

                  {/* CODE SLOT 4/6 */}
                  <div className="w-10 h-10 sm:w-12 sm:h-12 peer">
                    <input
                      disabled={submitting}
                      onKeyUp={({ key }) => {
                        if (key === 'Backspace') {
                          document.getElementById(`validation-input-3`)?.focus();
                          return
                        }
                      }}
                      inputMode="numeric"
                      tabIndex={4}
                      max={1}
                      maxLength={1}
                      value={code[3]}
                      onChange={(e) => {
                        setError(null);
                        const val = e.currentTarget ? e.currentTarget.value : '';
                        setCode((prev) => replaceValueAtIndex(prev, 3, val));
                      }}
                      className={clsx(error && '!border-error-dark !focus:ring-error-dark !focus:border-error-dark !text-error-dark', "disabled:text-gray-500 w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-main focus:border-primary-main")}
                      type="text"
                      name="validation-input-4"
                      id="validation-input-4" />
                  </div>

                  {/* CODE SLOT 5/6 */}
                  <div className="w-10 h-10 sm:w-12 sm:h-12 peer">
                    <input
                      disabled={submitting}
                      onKeyUp={({ key }) => {
                        if (key === 'Backspace') {
                          document.getElementById(`validation-input-4`)?.focus();
                          return;
                        }
                      }}
                      inputMode="numeric"
                      tabIndex={5}
                      max={1}
                      maxLength={1}
                      value={code[4]}
                      onChange={(e) => {
                        setError(null);
                        const val = e.currentTarget ? e.currentTarget.value : '';
                        setCode((prev) => replaceValueAtIndex(prev, 4, val));
                      }}
                      className={clsx(error && '!border-error-dark !focus:ring-error-dark !focus:border-error-dark !text-error-dark', "disabled:text-gray-500 w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-main focus:border-primary-main")}
                      type="text"
                      name="validation-input-5"
                      id="validation-input-5" />
                  </div>

                  {/* CODE SLOT 6/6 */}
                  <div className="w-10 h-10 sm:w-12 sm:h-12 peer">
                    <input
                      disabled={submitting}
                      onKeyUp={({ key }) => {
                        if (key === 'Backspace') {
                          document.getElementById(`validation-input-5`)?.focus();
                        }
                      }}
                      inputMode="numeric"
                      tabIndex={6}
                      max={1}
                      maxLength={1}
                      value={code[5]}
                      onChange={(e) => {
                        setError(null);
                        const val = e.currentTarget ? e.currentTarget.value : '';
                        setCode((prev) => replaceValueAtIndex(prev, 5, val));
                      }}
                      className={clsx(error && '!border-error-dark !focus:ring-error-dark !focus:border-error-dark !text-error-dark', "disabled:text-gray-500 w-full h-full flex flex-col items-center justify-center text-center outline-none rounded-xl border border-gray-200 bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-main focus:border-primary-main")}
                      type="text"
                      name="validation-input-6"
                      id="validation-input-6" />
                  </div>
                </div>
                {error && <span className="text-error-dark text-sm">{error}</span>}
              </div>

              <div className="flex flex-col space-y-4">
                {signInMethods && <span className="text-center text-sm font-medium space-x-1 text-gray-500">
                  Didn&apos;t receive code?&nbsp;
                  <button
                    type='button'
                    disabled={resendingCode}
                    onClick={async (e) => {
                      e.preventDefault();
                      setShowResendNotice(false);
                      setResendingCode(true);
                      // console.log('resend click', method)
                      if (method === 'email') {
                        const resendResponse = await emailOneTimePassword(signInMethods.email);
                        if (resendResponse.success) {
                          setResendingCode(false);
                          setShowResendNotice(true);
                        } else {
                          setError('There was a problem resending your code.')
                        }
                      }
                      if (method === 'phone') {
                        if (recaptcha) {
                          setError(null)
                          await signInWithPhoneNumber(auth, signInMethods.phone!, recaptcha)
                            .then((newConfirmationResult) => {
                              // console.log(newConfirmationResult)
                              setConfirmationResult(newConfirmationResult)
                              setShowResendNotice(true);
                            })
                            .catch((e) => { console.warn(e); setError('There was a problem resending your code.') })
                        } else {
                          setError('Please try another method.')
                        }
                      }
                      setResendingCode(false);
                    }} className={clsx(
                      resendingCode ? "text-gray-500" : "text-primary-main hover:text-secondary-main underline",
                    )}>Resend</button>
                  {showResendNotice &&
                    <p className="text-inherit animate-fade-in">Another code was sent.</p>
                  }
                </span>}
                <div className="flex flex-col space-y-2">
                  <button
                    disabled={submitting || !isSixDigitNumber(code.join(''))}
                    type="submit"
                    className="rounded-md bg-primary-main text-white font-semibold px-2 py-1.5 min-w-24 disabled:bg-gray-500 w-full">
                    {!submitting ? 'Verify Account' : <span className="icon-[uiw--loading] animate-spin" />}
                  </button>
                  <button
                    disabled={submitting}
                    type="button"
                    onClick={() => {
                      setSubmitting(false);
                      setShowResendNotice(false);
                      setCode(['', '', '', '', '', ''])
                      back();
                    }} className="block w-fit text-primary-main">Back</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};

function isSixDigitNumber(str: string): boolean {
  const sixDigitNumberRegex = /^\d{6}$/;
  return sixDigitNumberRegex.test(str);
}

function replaceValueAtIndex<T>(arr: T[], index: number, newValue: T): T[] {
  if (index < 0 || index >= arr.length) {
    throw new Error("Index out of bounds");
  }
  // Create a copy of the array to avoid mutating the original array
  const newArray = [...arr];
  // Replace the value at the specified index with the new value
  newArray[index] = newValue;
  return newArray;
}