export const listingStatusColor = (status: string) => {
  switch (status) {
    case "Vacant":
    case "Occupied":
      return "text-white ring-secondary-main bg-secondary-main";
    case "Dirty":
    case "Maintenance":
      return "error-main";
    case "Cleaning":
    case "Inspecting":
      return "warning-main";
    default:
      return "primary-main";
  }
};
