import { Dialog, DialogPanel, DialogTitle, Field, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Switch, Transition, TransitionChild } from '@headlessui/react';
import clsx from 'clsx';
import { addDays, addMonths, eachDayOfInterval, endOfMonth, format, isSameDay, isToday, setHours, setMinutes, startOfMonth, subMonths } from 'date-fns';
import { getAnalytics, logEvent } from "firebase/analytics";
import { FormEvent, useEffect, useId, useMemo, useState } from "react";
import { taskTypeOptions } from '../../classes/task/task-strings';
import User from "../../classes/user";
import useMakeSFRequest from "../request/salesforce/make-sf-call";
import { ApiTask } from "../types/task";
import { Attachment } from "./attachment";
import { useAuth } from "./auth/firebase-context";
import Combobox from "./input/combo-box";
import Modal from './modal';
import { buildGA4EventParams, GA4Event } from "../utils/analytics";
import brand from '../config/brand';
import { convertBillable, convertCurrency, extractFloatFromCurrencyText, handleBillableBlur, handleCurrencyBlur, handleFloatInputChange } from '../utils/maintenance-task-utils';

type TaskFormType = 'Maintenance' | 'Drop' | 'Pickup' | 'Clean' | 'Inspection' | 'Shuttle Service';
type TaskFormStatus = 'Completed' | 'Not Started' | 'In Progress' | 'On Hold' | 'Deferred' | 'Escalated';
type TaskFormPriority = 'URGENT' | 'High' | 'Normal' | 'Low' | 'Lowest';
export type TaskFormBase = {
  id?: string;
  type: TaskFormType;
  sub_type: string;
  status: TaskFormStatus;
  assignee_id: string;
  priority: TaskFormPriority;
}

type DeliveryTask = TaskFormBase & {
  inventory_id: string;
}
type InspectionTask = TaskFormBase & {
  type: 'Inspection';
  related_id: string;
  due_datetime: string;
}
type MaintenanceTask = TaskFormBase & {
  billable_hours: number;
  material_costs: number;
}
type ShuttleServiceTask = TaskFormBase & {
  type: 'Shuttle Service';
  waiver_verified: boolean;
  pickup_location: string;
  dropoff_location: string;
  number_of_adults: number;
  number_of_children: number;
  description: string;
  reporter_id: string;
  due_datetime: string;
}

type TaskForm<T = Omit<TaskFormType, 'Inspection' | 'Shuttle Service'>> = TaskFormBase & {
  type: T;
  title: string;
  description: string;
  related_id: string;
  assignee_id: string;
  status: TaskFormStatus;
  reporter_id: string;
  priority: TaskFormPriority;
  due_datetime: string;
  permission_to_enter: boolean
};

const TaskPriorityOptions: TaskFormPriority[] = ['URGENT', 'High', 'Normal', 'Low', 'Lowest'];
const TaskStatusOptions: TaskFormStatus[] = ['Completed', 'Not Started', 'In Progress', 'On Hold', 'Deferred', 'Escalated'];
const TaskTypeOptions: TaskFormType[] = ['Maintenance', 'Drop', 'Pickup', 'Clean', 'Inspection', 'Shuttle Service'];
const TaskSubTypeMaintenanceOptions = ["Installation", "Paint", "Parts", "Patch & Paint", "Repair", "Replacement", "Service", "Other"];
const TaskSubTypeDropOptions = ["Bedding", "Consumables", "Inventory", "Rentals", "Towels"];
const TaskSubTypePickupOptions = ["Bedding", "Consumables", "Inventory", "Rentals", "Towels"];
const TaskSubTypeCleanOptions = ["Carpet Encapsulation", "Carpet Shampooing", "Drop & Strip", "Deep Clean", "Departure Clean", "Drapery Cleaning", "Full Clean", "Glass & Lanai", "Glass Restoration", "Grill Cleaning", "Grout Cleaning", "Hood Cleaning", "Interior Tile Cleaning", "Lanai Clean", "Lanai Tile Cleaning", "Linens & Towels", "Linens", "Tidy", "Towels", "Upholstery Cleaning", "Other"]
const TaskSubTypeInspectionOptions = ["Maintenance", "Quality Assurance", "Final"];
const TaskSubTypeShuttleServiceOptions = ["Guest", "Owner", "Other"];
export const TaskShuttleLocationOption = [
  "Honua Kai - Hokulani Tower",
  "Honua Kai - Konea Tower",
  "Honua Kai - Luana Gardens",
  "Kaanapali - Resort Golf Courses",
  "Kaanapali - The Alii",
  "Kaanapali - The Whaler",
  "Kaanapali - Whalers Village",
  "Kahului Airport",
  "Kapalua - Bay Course",
  "Kapalua - Plantation Course",
  "Kapalua - Village Center",
  "Lahaina - Banyan Tree",
  "Lahaina - Cannery Mall",
  "Lahaina - Outlets of Maui",
  "Lahaina - Puunoa Beach Estates",
  "Maui Resort Rentals - North",
  "Maui Resort Rentals - South",
  "The Mahana",
];
const makeTaskSubtypeOptions = (type: TaskFormType) => {
  switch (type) {
    case 'Maintenance': return TaskSubTypeMaintenanceOptions;
    case 'Drop': return TaskSubTypeDropOptions;
    case 'Pickup': return TaskSubTypePickupOptions;
    case 'Clean': return TaskSubTypeCleanOptions;
    case 'Inspection': return TaskSubTypeInspectionOptions;
    case 'Shuttle Service': return TaskSubTypeShuttleServiceOptions;
    default: return []
  };
};

const createFormValues = (type: TaskFormType, user?: User | null, task?: ApiTask, followUpTask?: boolean, presetListingId?: string) => {
  const values: TaskFormBase = {
    ...(task && !followUpTask && { id: task.id }),
    type: type,
    sub_type: task?.sub_type as string || '',
    assignee_id: task?.assignee_id as string || '',
    status: followUpTask ? 'Not Started' : (task?.status as TaskFormStatus || 'Not Started'),
    priority: task?.priority as TaskFormPriority || 'Normal',
  };

  if (!['Inspection', 'Shuttle Service'].includes(type)) {
    return {
      ...values,
      title: followUpTask ? '' : task?.title || '',
      related_id: presetListingId ? presetListingId : (task?.related_id || ''),
      reporter_id: task?.reporter_id || user?.sf_user_id || '',
      due_datetime: task?.due_datetime || setTimeOnDate(new Date(), '10:00 AM').toJSON(),
      description: followUpTask ? '' : task?.description || '',
      permission_to_enter: task?.permission_to_enter !== undefined ? task.permission_to_enter : true,
      ...(['Drop', 'Pickup'].includes(type) && { inventory_id: task?.inventory_id || '' }),
      ...(type === 'Maintenance' && {
        billable_hours: convertBillable(String(task?.billable_hours || 0)),
        material_costs: convertCurrency(String(task?.material_costs || 0))
      })
    } as TaskForm<typeof type>;
  }

  if (type === 'Inspection') {
    return {
      ...values,
      related_id: presetListingId ? presetListingId : (task?.related_id || ''),
      due_datetime: task?.due_datetime || setTimeOnDate(new Date(), '10:00 AM').toJSON(),
    } as InspectionTask;
  }

  if (type === 'Shuttle Service') {
    return {
      ...values,
      waiver_verified: task?.waiver_verified !== undefined ? task.waiver_verified : false,
      pickup_location: task?.pickup_location || '',
      dropoff_location: task?.dropoff_location || '',
      number_of_adults: task?.number_of_adults || 1,
      number_of_children: task?.number_of_children || 0,
      description: followUpTask ? '' : task?.description || '',
      reporter_id: user?.sf_user_id || '',
      due_datetime: task?.due_datetime || setTimeOnDate(new Date(), '10:00 AM').toJSON()
    } as ShuttleServiceTask;
  }
  return values;
};

const validateValues = (values: Record<string, any>, type: TaskFormType) => {
  switch (type) {
    case 'Inspection':
      if (!TaskTypeOptions.includes(values.type)) { return false }
      if (!makeTaskSubtypeOptions(type).includes(values.sub_type)) { return false }
      if (!values.assignee_id) { return false }
      if (!TaskStatusOptions.includes(values.status)) { return false }
      if (!TaskPriorityOptions.includes(values.priority)) { return false }
      if (!values.related_id) { return false }
      return true
    case 'Shuttle Service':
      if (!TaskTypeOptions.includes(values.type)) { return false }
      if (!makeTaskSubtypeOptions(type).includes(values.sub_type)) { return false }
      if (!values.assignee_id) { return false }
      if (!TaskStatusOptions.includes(values.status)) { return false }
      if (!TaskPriorityOptions.includes(values.priority)) { return false }
      if (!TaskShuttleLocationOption.includes(values.pickup_location)) { return false }
      if (!TaskShuttleLocationOption.includes(values.dropoff_location)) { return false }
      if (!values.due_datetime) { return false }
      if (values.number_of_adults < 1) { return false }
      if (values.number_of_children < 0) { return false }
      if (!values.reporter_id) { return false }
      return true
    case 'Clean':
    case 'Drop':
    case 'Maintenance':
    case 'Pickup':
    default: {
      if (type === 'Maintenance') {
        //TODO: Get a visible error on this number input. For some reason setFormError is out of scope.
        //      Is it due to the nested sub-forms for certain task types?

        // enforce MAX 100 billable hours (like Apex does)
        const billableHours = (values as MaintenanceTask).billable_hours;
        if (typeof billableHours === 'string') {
          const hoursAsFloat = parseFloat(billableHours);
          if (hoursAsFloat < 0 || hoursAsFloat > 100) {
            const errorMsg = 'Billable hours out of range (must be less than 100): ' + hoursAsFloat;
            console.warn(errorMsg);
            // setFormError(errorMsg);
            return false
          }
        }
        // enforce MAX $1,000,000 mat. costs (like Apex does)
        const materialCosts = (values as MaintenanceTask).material_costs;
        if (typeof materialCosts === 'string') {
          const extracted: any = extractFloatFromCurrencyText(materialCosts);
          if (extracted < 0 || extracted > 1000000) {
            const errorMsg = 'Material costs out of range (must be less than $1,000,000): ' + extracted;
            console.warn(errorMsg);
            // setFormError(errorMsg);
            return false
          }
        }
      }

      if (!TaskTypeOptions.includes(values.type)) { return false }
      if (!makeTaskSubtypeOptions(type).includes(values.sub_type)) { return false }
      if (!values.assignee_id) { return false }
      if (!TaskStatusOptions.includes(values.status)) { return false }
      if (!TaskPriorityOptions.includes(values.priority)) { return false }
      if (!values.title) { return false }
      if (!values.related_id) { return false }
      if (!values.reporter_id) { return false }
      if (!values.due_datetime) { return false }
      return true
    }
  }
}

const TaskForm: React.FC<{
  formId: string,
  users: { id: string, label: string }[],
  listings: { id: string, label: string }[],
  usersInitialized: boolean,
  listingsInitialized: boolean,
  loadingUsers: boolean,
  loadingListings: boolean,
  onSuccess(a: ApiTask): void,
  setLoading(a: boolean): void,
  loading: boolean,
  disableSave?(a: boolean): void,
  task?: ApiTask,
  followUpTask?: boolean,
  setShowConfirmEdit(a: boolean): void,
  showConfirmEdit: boolean,
  presetListingId?: string
}> = ({
  formId,
  listings,
  listingsInitialized,
  users,
  usersInitialized,
  loadingListings,
  loadingUsers,
  onSuccess,
  setLoading,
  loading,
  disableSave,
  task,
  followUpTask = false,
  setShowConfirmEdit,
  showConfirmEdit,
  presetListingId
}) => {
    const { user } = useAuth();
    const id = useId();

    const [values, setValues] = useState(createFormValues(task?.type as TaskFormType || 'Maintenance' as const, user, task, followUpTask, presetListingId))
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [attachmentsLoading, setAttachmentsLoading] = useState(false);
    const [attachments, setAttachments] = useState<File[]>([]);
    const datePickerStr = useMemo(() => (values as TaskForm<typeof curType>).due_datetime, [values]);
    const [formError, setFormError] = useState<string | null>(null)
    const { type: curType } = values;
    const subTypeOptions = useMemo(() => makeTaskSubtypeOptions(curType), [curType])
    const request = useMakeSFRequest();
    useEffect(() => {
      setValues(createFormValues(curType, user, task, followUpTask, presetListingId))
    }, [user])
    useEffect(() => {
      if (typeof disableSave === 'function') {
        disableSave(validateValues(values, curType))
      }
    }, [values, disableSave])
    const onSubmit = async (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);

      //[[ANALYTICS]]
      const analytics = getAnalytics();
      logEvent(analytics, GA4Event.CreateTask, buildGA4EventParams(brand, user, null, null, values));

      // translate the special maintenance fields types
      if (curType === 'Maintenance') {
        const billableHours = (values as MaintenanceTask).billable_hours;
        if (typeof billableHours === 'string') {
          const hoursAsFloat = parseFloat(billableHours);
          (values as MaintenanceTask).billable_hours = isNaN(hoursAsFloat) ? 0 : hoursAsFloat;
        }
        const materialCosts = (values as MaintenanceTask).material_costs;
        if (typeof materialCosts === 'string') {
          const extracted: any = extractFloatFromCurrencyText(materialCosts);
          (values as MaintenanceTask).material_costs = isNaN(extracted) ? 0 : extracted;
        }
      }

      if (validateValues(values, curType)) {
        const postResponse = await request({ endpointPath: '/api/ohana/task', method: 'POST', body: JSON.stringify(values) })
        if (postResponse.status === 200) {
          if (attachments.length === 0) {
            onSuccess((postResponse as any).task);
            setLoading(false);
            return;
          }
          setAttachmentsLoading(true);
          const requests = attachments.map((file) => {
            return request({ endpointPath: `/api/attachment/${(postResponse as any).task.id}?filename=${file.name}`, method: 'POST', body: file, contentType: file.type })
          })
          const attachmentResponses = await Promise.all(requests)
          onSuccess((postResponse as any).task);
          setAttachmentsLoading(false);
          setLoading(false);
          return;
        }
        setFormError('There was a problem creating this task.');
        setLoading(false);
        return;
      }
      setFormError('Please check the provied information for any errors or missing fields.');
      setLoading(false);
      return;
    };

    //NOTE: Some tasks are related to non-listings, like a case or reservation. For those tasks,
    //      the user may not change the 'related' object. Also, we show them the name of the
    //      related object, rather than a listing name.
    const relatedToListing = !task || (task && task.id && task.related_type === "Listing__c");
    return (
      <form id={formId} onSubmit={onSubmit}>
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">{followUpTask ? 'Create Follow Up Task' : task ? 'Edit Task' : 'Create Task'}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Assignee will receive a notification by text message if assigned a task within 24 hours.</p>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <label htmlFor={`type-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                Type
              </label>
              <div className="mt-1">
                <select
                  disabled={loading}
                  value={values.type}
                  onChange={(e) => {
                    const val = e.currentTarget.value as TaskFormType;
                    setValues(createFormValues(val, user, task, followUpTask));
                  }}
                  onInput={(e) => {
                    const val = e.currentTarget.value as TaskFormType;
                    setValues(createFormValues(val, user, task, followUpTask));
                  }}
                  id={`type-${id}`}
                  name="type"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:max-w-xs sm:text-sm sm:leading-6 truncate"
                >
                  {taskTypeOptions.map((option) => (
                    <option value={option} key={`task-type-option-${option}`}>{option}</option>
                  ))}
                </select>
              </div>
            </div>

            {values.hasOwnProperty('sub_type') &&
              <div className="sm:col-span-1">
                <label htmlFor={`sub-type-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Sub Type
                </label>
                <div className="mt-1">
                  <select
                    disabled={loading}
                    value={values.sub_type}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      if (subTypeOptions.includes(value)) {
                        setValues(prev => ({ ...prev, sub_type: value } as typeof prev));
                        return;
                      }
                    }}
                    onInput={(e) => {
                      const { value } = e.currentTarget;
                      if (subTypeOptions.includes(value)) {
                        setValues(prev => ({ ...prev, sub_type: value } as typeof prev));
                        return;
                      }
                    }}
                    id={`sub-type-${id}`}
                    name="subType"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:max-w-xs sm:text-sm sm:leading-6 truncate"
                  >
                    <option value=''></option>
                    {subTypeOptions.map((option) => (
                      <option value={option} key={`task-sub-type-option-${option}`}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
            }
            {((curType === 'Drop' || curType === 'Pickup') && values.hasOwnProperty('inventory_id')) &&
              <div className="sm:col-span-1">
                <label htmlFor={`sub-type-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Inventory Id
                </label>
                <div className="mt-1">
                  <input
                    disabled={loading}
                    type={'text'}
                    value={(values as DeliveryTask).inventory_id}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      setValues(prev => ({ ...prev, inventory_id: value } as typeof prev));
                    }}
                    onInput={(e) => {
                      const { value } = e.currentTarget;
                      setValues(prev => ({ ...prev, inventory_id: value } as typeof prev));
                    }}
                    id={`inventory_id-${id}`}
                    name="inventory_id"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:max-w-xs sm:text-sm sm:leading-6 truncate"
                  />
                </div>
              </div>
            }
            {values.hasOwnProperty('pickup_location') &&
              <div className="sm:col-span-1">
                <label htmlFor={`pickup-location-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Pick Up Location
                </label>
                <div className="mt-1">
                  <select
                    disabled={loading}
                    value={(values as ShuttleServiceTask).pickup_location}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      if (TaskShuttleLocationOption.includes(value)) {
                        setValues(prev => ({ ...prev, pickup_location: value } as typeof prev));
                        return;
                      }
                    }}
                    onInput={(e) => {
                      const { value } = e.currentTarget;
                      if (TaskShuttleLocationOption.includes(value)) {
                        setValues(prev => ({ ...prev, pickup_location: value } as typeof prev));
                        return;
                      }
                    }}
                    id="pickup_location"
                    name={`pickup-location-${id}`}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:max-w-xs sm:text-sm sm:leading-6 truncate"
                  >
                    <option value=''></option>
                    {TaskShuttleLocationOption.map((option) => (
                      <option value={option} key={`task-shuttle-option-${option}`}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
            }
            {values.hasOwnProperty('dropoff_location') &&
              <div className="sm:col-span-1">
                <label htmlFor={`dropoff-location-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Drop Off Location
                </label>
                <div className="mt-1">
                  <select
                    disabled={loading}
                    value={(values as ShuttleServiceTask).dropoff_location}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      if (TaskShuttleLocationOption.includes(value)) {
                        setValues(prev => ({ ...prev, dropoff_location: value } as typeof prev));
                        return;
                      }
                    }}
                    onInput={(e) => {
                      const { value } = e.currentTarget;
                      if (TaskShuttleLocationOption.includes(value)) {
                        setValues(prev => ({ ...prev, dropoff_location: value } as typeof prev));
                        return;
                      }
                    }}
                    id={`dropoff-location-${id}`}
                    name="dropoff_location"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:max-w-xs sm:text-sm sm:leading-6 truncate"
                  >
                    <option value=''></option>
                    {TaskShuttleLocationOption.map((option) => (
                      <option value={option} key={`task-sub-type-option-${option}`}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
            }
            {values.hasOwnProperty('number_of_adults') && values.hasOwnProperty('number_of_children') &&
              <div className="sm:col-span-2 grid grid-cols-2 gap-4">
                <div className="col-span-1">
                  <label htmlFor={`number-of-adults-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                    # Adults
                  </label>
                  <div className="mt-1">
                    <div className="relative flex items-center max-w-[8rem]">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setValues((prev) => ({ ...prev, number_of_adults: Math.max((prev as ShuttleServiceTask).number_of_adults - 1, 1) }) as typeof prev)
                        }}
                        type="button"
                        id="decrement-button-adults"
                        data-input-counter-decrement="quantity-input"
                        className=" border border-gray-300 rounded-s-lg p-3 pt-2 h-9 focus:ring-1 focus:ring-inset focus:outline-none">
                        <span className="icon-[ic--baseline-minus]" />
                      </button>
                      <input
                        inputMode="numeric"
                        value={(values as ShuttleServiceTask).number_of_adults || 0}
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setValues((prev) => ({ ...prev, number_of_adults: Math.max(Number(value), 1) }) as typeof prev)
                        }}
                        type="text"
                        id={`number-of-adults-${id}`}
                        data-input-counter
                        aria-describedby="number-of-adults"
                        className="border-gray-300 focus:border-gray-300 focus:border-1 focus:border-x-0 border-x-0 h-9 text-center text-sm block w-full py-2.5 placeholder-gray-900 focus:ring-1 focus:ring-inset"
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setValues((prev) => ({ ...prev, number_of_adults: Math.max((prev as ShuttleServiceTask).number_of_adults + 1, 1) }) as typeof prev)
                        }}
                        type="button"
                        id="increment-button-adults"
                        data-input-counter-increment="quantity-input" className="border border-gray-300 rounded-e-lg p-3 pt-2 h-9 focus:ring-1 focus:ring-inset focus:outline-none">
                        <span className="icon-[ic--baseline-plus]" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  <label htmlFor={`number-of-children-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                    # Children
                  </label>
                  <div className="mt-1">
                    <div className="relative flex items-center max-w-[8rem]">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setValues((prev) => ({ ...prev, number_of_children: Math.max((prev as ShuttleServiceTask).number_of_children - 1, 0) }) as typeof prev)
                        }}
                        type="button"
                        id="decrement-button"
                        data-input-counter-decrement="quantity-input"
                        className="border border-gray-300 rounded-s-lg p-3 pt-2 h-9 focus:ring-1 focus:ring-inset focus:outline-none">
                        <span className="icon-[ic--baseline-minus]" />
                      </button>
                      <input
                        value={(values as ShuttleServiceTask).number_of_children}
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setValues((prev) => ({ ...prev, number_of_children: Math.max(Number(value), 0) }) as typeof prev)
                        }}
                        type="number"
                        id={`number-of-children-${id}`}
                        data-input-counter
                        aria-describedby="number-of-children"
                        className="border-gray-300 focus:border-gray-300 focus:border-1 focus:border-x-0 border-x-0 h-9 text-center text-sm block w-full py-2.5 placeholder-gray-900 focus:ring-1 focus:ring-inset"
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setValues((prev) => ({ ...prev, number_of_children: Math.max((prev as ShuttleServiceTask).number_of_children + 1, 0) }) as typeof prev)
                        }}
                        type="button"
                        id="increment-button-num-children"
                        data-input-counter-increment="quantity-input"
                        className="border border-gray-300 rounded-e-lg p-3 pt-2 h-9 focus:ring-1 focus:ring-inset focus:outline-none">
                        <span className="icon-[ic--baseline-plus]" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
            {values.hasOwnProperty('title') &&
              <div className="sm:col-span-full">
                <label htmlFor={`title-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Title
                </label>
                <div className="mt-1">
                  <input
                    maxLength={250} // stay inside the SF limit
                    disabled={loading}
                    value={(values as TaskForm<typeof curType>).title}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      setValues((prev) => ({ ...prev, title: value }) as typeof prev)
                    }}
                    type="text"
                    name="title"
                    id={`title-${id}`}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:text-sm sm:leading-6"
                  />
                </div>
              </div>}

            {values.hasOwnProperty('description') &&
              <div className="col-span-full">
                <label htmlFor={`description-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Description
                </label>
                <div className="mt-1">
                  <textarea
                    maxLength={32000 - 5} // stay inside the SF limit
                    disabled={loading}
                    id={`description-${id}`}
                    name="description"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:text-sm sm:leading-6"
                    value={(values as any).description}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      setValues((prev) => ({ ...prev, description: value }) as typeof prev)
                    }}
                  />
                </div>
              </div>}
            {(curType === 'Maintenance') &&
              <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 col-span-full sm:grid-cols-2">
                {values.hasOwnProperty('billable_hours') &&
                  <div className="sm:col-span-1">
                    <label htmlFor={`title-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                      Billable Hours
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        disabled={loading}
                        value={(values as MaintenanceTask).billable_hours}
                        onBlur={(e) => {
                          const parsedValue = handleBillableBlur(e);
                          setValues((prev) => ({ ...prev, billable_hours: parsedValue }) as typeof prev)
                        }}
                        onChange={(e) => {
                          const parsedValue = handleFloatInputChange(e);
                          setValues((prev) => ({ ...prev, billable_hours: parsedValue }) as typeof prev)
                        }}
                        name="billable-hours"
                        id={`billable-hours-${id}`}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                }

                {values.hasOwnProperty('material_costs') &&
                  <div className="sm:col-span-1">
                    <label htmlFor={`title-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                      Material Costs
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        disabled={loading}
                        value={(values as MaintenanceTask).material_costs}
                        onBlur={(e) => {
                          const parsedValue = handleCurrencyBlur(e);
                          setValues((prev) => ({ ...prev, material_costs: parsedValue }) as typeof prev)
                        }}
                        onChange={(e) => {
                          const parsedValue = handleFloatInputChange(e);
                          setValues((prev) => ({ ...prev, material_costs: parsedValue }) as typeof prev)
                        }}
                        name="material-costs"
                        id={`material-costs-${id}`}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                }
              </div>
            }
            {relatedToListing && values.hasOwnProperty('related_id') &&
              <div className="col-span-full">
                <Combobox
                  id={`related-id-${id}`}
                  disabled={loadingListings || loading || !listingsInitialized}
                  options={!listingsInitialized ? [] : listings}
                  label='Related To'
                  value={(values as TaskForm<typeof curType>).related_id}
                  setValue={(option) => { setValues(prev => ({ ...prev, related_id: option || '' } as typeof prev)); }}
                  defaultOption={{ id: task?.related_id || '', label: task?.related_id || '' }}
                />
              </div>
            }
            {!relatedToListing && values.hasOwnProperty('related_id') &&
              <div className="sm:col-span-full">
                <label htmlFor={`related-id-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                  Related To
                </label>
                <div className="mt-1">
                  <input
                    disabled={true}
                    // defaultValue={(task && task?.related.name) || 'Unknown'}
                    defaultValue={(task && task?.related?.name) || 'Unknown'}
                    type="text"
                    name={`related-id-${id}`}
                    id={`related-id-${id}`}
                    className="disabled:text-gray-300 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            }
            {values.hasOwnProperty('assignee_id') &&
              <div className="sm:col-span-full flex items-end justify-between gap-4">
                <div className='grow'>
                  <Combobox
                    groups={['Users', 'Groups']}
                    id={`assignee-id-${id}`}
                    disabled={loadingUsers || loading || !usersInitialized}
                    options={!usersInitialized ? [] : users}
                    label='Assignee'
                    value={(values as TaskForm<typeof curType>).assignee_id}
                    setValue={(option) => { setValues(prev => ({ ...prev, assignee_id: option } as typeof prev)); }}
                    defaultOption={{ id: task?.assignee_id || '', label: task?.assignee && task.assignee.name ? task.assignee.name : '' }}
                  />
                </div>
                {values.hasOwnProperty('permission_to_enter') &&
                  <div className='flex items-center'>
                    <button type="button" disabled={loading} className='flex' onClick={() => { setValues((prev) => ({ ...prev, permission_to_enter: !(values as TaskForm<typeof curType>).permission_to_enter })) }}>
                      {/*
                        NOTE: These hover colors get stuck on mobile, so they (currently) match the non-hover.
                        TODO: It's minor, because there is no hover on mobile, but it's worth addressing as a polish issue.
                      */}
                      {(values as TaskForm<typeof curType>).permission_to_enter
                        ? <span className='icon-[mdi--unlocked-check] w-9 h-9 text-success-dark hover:text-success-dark' />
                        : <span className='icon-[mdi--unlocked-minus] w-9 h-9 text-error-dark hover:text-error-dark' />}
                    </button>
                  </div>
                }
                {values.hasOwnProperty('waiver_verified') &&
                  <Field disabled={loading} as="div" className="flex flex-col items-start justify-between">
                    <Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                      Waiver
                    </Label>

                    <Switch
                      disabled={loading}
                      checked={(values as ShuttleServiceTask).waiver_verified}
                      onChange={(checked) => { setValues((prev) => ({ ...prev, waiver_verified: checked })) }}
                      className={clsx(
                        (values as ShuttleServiceTask).waiver_verified ? 'bg-primary-main' : 'bg-gray-200',
                        'mt-1 relative inline-flex h-9 w-16 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-main focus:ring-offset-2'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={clsx(
                          (values as ShuttleServiceTask).waiver_verified ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-8 w-8 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out items-center justify-center'
                        )}
                      >
                        <span className='icon-[solar--document-add-outline] h-6 w-6 mt-1' />
                      </span>
                    </Switch>
                  </Field>
                }
              </div>
            }
          </div>
          {values.hasOwnProperty('reporter_id') && user &&
            <div className="sm:col-span-full mt-3">
              <Combobox
                groups={['Users', 'Groups']}
                id={`reporter_id-${id}`}
                disabled={loadingUsers || loading || !usersInitialized}
                options={!usersInitialized ? [] : users}
                label='Reporter'
                value={(values as TaskForm<typeof curType>).reporter_id}
                setValue={(option) => { console.log(option); setValues(prev => ({ ...prev, reporter_id: option })); }}
                defaultOption={{ id: task?.reporter_id || user.sf_user_id, label: task?.reporter && task.reporter?.name ? task.reporter.name : user.name }}
              />
            </div>}
          {values.hasOwnProperty('status') &&
            <div className="sm:col-span-full mt-3">
              <label htmlFor={`status-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                Status
              </label>
              <div className="mt-1">
                <select
                  disabled={loading}
                  value={values.status}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    if (TaskStatusOptions.includes((value as TaskFormStatus))) {
                      setValues(prev => ({ ...prev, status: value as TaskFormStatus } as typeof prev));
                      return;
                    }
                  }}
                  onInput={(e) => {
                    const { value } = e.currentTarget;
                    if (TaskStatusOptions.includes((value as TaskFormStatus))) {
                      setValues(prev => ({ ...prev, status: value as TaskFormStatus } as typeof prev));
                      return;
                    }
                  }}
                  id={`status-${id}`}
                  name="status"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:text-sm sm:leading-6"
                >
                  {TaskStatusOptions.map((option) => (
                    <option value={option} key={`task-status-option-${option}`}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          }
          {values.hasOwnProperty('priority') &&
            <div className="sm:col-span-full mt-3">
              <label htmlFor={`priority-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                Priority
              </label>
              <div className="mt-1">
                <select
                  disabled={loading}
                  value={values.priority}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    if (TaskPriorityOptions.includes((value as TaskFormPriority))) {
                      setValues(prev => ({ ...prev, priority: value as TaskFormPriority } as typeof prev));
                      return;
                    }
                  }}
                  onInput={(e) => {
                    const { value } = e.currentTarget;
                    if (TaskPriorityOptions.includes((value as TaskFormPriority))) {
                      setValues(prev => ({ ...prev, priority: value as TaskFormPriority } as typeof prev));
                      return;
                    }
                  }}
                  id={`priority-${id}`}
                  name="priority"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:text-sm sm:leading-6"
                >
                  {TaskPriorityOptions.map((option) => (
                    <option value={option} key={`task-priority-option-${option}`}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          }
          {values.hasOwnProperty('due_datetime') &&
            <div className='sm:col-span-full mt-3'>
              <label htmlFor={`due-datetime-${id}`} className="block text-sm font-medium leading-6 text-gray-900">
                {curType === 'Shuttle Service' ? 'Time' : 'Due Date'}
              </label>
              <div className='mt-1'>
                <button type="button" disabled={loading} className='w-full text-sm ring-1 ring-gray-300 rounded-md py-2 flex px-2' onClick={() => { setOpenDatePicker(true) }}>
                  <span className='icon-[uil--calender] w-5 h-5 mr-2' />
                  <span className='truncate'>{format(new Date(datePickerStr), 'PP @ h:mm a')}</span>
                </button>
                <Modal open={openDatePicker} setOpen={setOpenDatePicker}>
                  <DayPicker id={`due-datetime-${id}`} date={datePickerStr} setDate={(d) => { setValues(prev => ({ ...prev, due_datetime: d })); setOpenDatePicker(false); }} />
                </Modal>
              </div>
            </div>
          }
          {attachments.length > 0 &&
            <div className="grid-cols-3 grid gap-2 mt-2">
              {attachments.map((attach, attachIndex) => (
                <div key={`${attach.name}-${attachIndex}-preview`}>
                  <Attachment disableFullscreen loading={attachmentsLoading} onDelete={async (fileName) => { setAttachments((prev) => prev.filter((file) => file.name !== fileName)) }} file={attach} />
                </div>
              ))}
            </div>}
          <div className="sm:col-span-full mt-3 flex">
            <div className="w-full flex justify-end items-center text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-primary-main focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-main focus-within:ring-offset-2 hover:text-secondary-main"
              >
                <span className="icon-[fluent--attach-24-filled] text-gray-500 mr-1" aria-hidden="true" />
                <span>Upload a file</span>
                <input
                  disabled={loading}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onInput={(e) => {
                    if (e.currentTarget.files) {
                      const filesPayload: File[] = [];
                      let fileIndex = 0;
                      while (fileIndex < e.currentTarget.files.length) {
                        filesPayload.push(e.currentTarget.files[fileIndex]);
                        fileIndex += 1
                      }
                      setAttachments((prev) => ([...prev, ...filesPayload]));
                      return;
                    }
                    setAttachments([]);
                  }} />
              </label>
            </div>
          </div>
        </div>
        {task && task.id && <Transition show={showConfirmEdit}>
          <Dialog id={`confirmation-action-${id}`} className="relative z-[100]" onClose={setShowConfirmEdit}>
            <TransitionChild
              as='div'
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center text-center sm:items-center p-0">
                <TransitionChild
                  as='div'
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-screen sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <span className="icon-[heroicons-solid--exclamation] h-6 w-6 text-error-main" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                          Confirm Edit Task
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you would like to edit this task?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        id={`edit-task-submit-${task.id}`}
                        autoFocus
                        type="submit"
                        disabled={loading}
                        className="disabled:bg-gray-500 inline-flex w-full sm:w-24 justify-center rounded-md bg-error-dark px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-error-main sm:ml-3 focus:ring-0"
                        form={formId}
                      >
                        {loading ? <span className='icon-[uiw--loading] animate-spin' /> : <span>Confirm</span>}
                      </button>
                      <button
                        type="button"
                        disabled={loading}
                        className="disabled:text-gray-300 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => { setShowConfirmEdit(false); }}
                        data-autofocus
                      >
                        Cancel
                      </button>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>}
      </form >
    )
  };

interface Day {
  date: Date;
  isCurrentMonth?: boolean;
  isSelected?: boolean;
  isToday?: boolean;
}

const generateDays = (date: string, selectedDate?: string, time: string = '10:00 AM'): Day[] => {
  const startDate = startOfMonth(setTimeOnDate(new Date(date), time));
  const endDate = endOfMonth(startDate);

  const startDay = startDate.getDay();
  const endDay = endDate.getDay();

  const days: Day[] = [];

  // Fill the days before the start of the month
  for (let i = startDay - 1; i >= 0; i--) {
    const date = addDays(startDate, -i - 1);
    days.push({
      date: setTimeOnDate(date, time)
    });
  }

  // Fill the days in the current month
  eachDayOfInterval({ start: startDate, end: endDate }).forEach(date => {
    days.push({
      date: setTimeOnDate(date, time),
      isCurrentMonth: true,
      isToday: isToday(date),
      isSelected: selectedDate ? isSameDay(new Date(selectedDate), date) : undefined
    });
  });

  // Fill the days after the end of the month
  for (let i = 1; i <= 6 - endDay; i++) {
    const date = addDays(endDate, i);
    days.push({
      date: setTimeOnDate(date, time)
    });
  }

  return days;
};

const DayPicker: React.FC<{ date: string, setDate(d: string): void, id: string }> = ({ date, setDate, id }) => {
  const [days, setDays] = useState(generateDays(date, date, format(new Date(date), 'h:mm a')));
  const [viewDay, setViewDay] = useState(days.find(day => day.isSelected));
  const label = format(new Date(date), 'PPPP @ h:mm a');
  return (
    <div id={id}>
      <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-0">
        <h2 className="flex-auto text-sm sm:text-base font-semibold text-gray-900 truncate">{label}</h2>
        <div className="flex items-center">
          <button
            onClick={() => {
              const d = viewDay?.date || new Date(date);
              const ms = startOfMonth(d);
              const lm = subMonths(ms, 1)
              const nd = generateDays(lm.toUTCString(), date);
              setViewDay(nd.find(day => day.isSelected) || nd[0])
              setDays(nd)
            }}
            type="button"
            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Previous month</span>
            <span className="icon-[ic--round-keyboard-arrow-left] h-5 w-5" aria-hidden="true" />
          </button>
          <h2 className="flex-auto text-sm sm:text-base text-gray-500 text-center truncate">{format(viewDay?.date || new Date(date), 'MMMM yyyy')}</h2>
          <button
            onClick={() => {
              const d = viewDay?.date || new Date(date);
              const ms = startOfMonth(d);
              const nm = addMonths(ms, 1)
              const nd = generateDays(nm.toUTCString(), date);
              setViewDay(nd.find(day => day.isSelected) || nd[10])
              setDays(nd)
            }}
            type="button"
            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Next month</span>
            <span className="icon-[ic--round-keyboard-arrow-right] h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div className="mt-2 grid grid-cols-7 text-sm">
        {days.map((day, dayIdx) => (
          <div key={`${format(day.date, 'yyyy-MM-dd-h:mm-a')}-${dayIdx}`} className={clsx(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}>
            <TimeInput day={day} setDate={setDate} id={id} />
          </div>
        ))}
      </div>
    </div>
  )
}

const timeOptions = [
  "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM",
  "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
  "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM",
  "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM",
  "6:00 PM"
];

const TimeInput: React.FC<{ day: Day, setDate(d: string): void, id: string }> = ({ day, setDate, id }) => {
  return (
    <>
      <Listbox
        value={day.date}
        onChange={(time) => {
          if (time) { setDate(time.toJSON()) }
        }}>
        {({ open }) => (
          <>
            <div className="relative">
              <ListboxButton
                type="button"
                className={clsx(
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isToday && 'text-primary-main',
                  !day.isSelected && !day.isToday && day.isCurrentMonth && 'text-gray-900',
                  !day.isSelected && !day.isToday && !day.isCurrentMonth && 'text-gray-400',
                  day.isSelected && day.isToday && 'bg-primary-main',
                  day.isSelected && !day.isToday && 'bg-gray-900',
                  !day.isSelected && 'hover:bg-gray-200',
                  (day.isSelected || day.isToday) && 'font-semibold',
                  'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
                  'relative w-full cursor-default'
                )}
              >
                <time dateTime={day.date.toJSON()}>{format(day.date, 'd')}</time>
              </ListboxButton>

              <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <ListboxOptions className="absolute z-[1000] mt-1 max-h-40 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {timeOptions.map((time, i) => {
                    const selected = format(day.date, 'HH:mm a') === time;
                    return (
                      <ListboxOption
                        key={`${day.date}-${time}-${i}-${id}`}
                        className={({ focus }) =>
                          clsx(
                            focus || selected ? 'bg-primary-main text-white' : '',
                            !focus ? 'text-gray-900' : '',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={setTimeOnDate(day.date, time)}
                      >
                        {({ focus }) => (
                          <>
                            <span className={clsx(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {time}
                            </span>

                            {selected ? (
                              <span
                                className={clsx('text-white absolute inset-y-0 right-0 flex items-center'
                                )}
                              >
                                <span className="pl-8 icon-[grommet-icons--checkbox-selected] w-4 h-4" aria-hidden='true' />
                              </span>
                            ) : null}
                          </>
                        )}
                      </ListboxOption>
                    )
                  })}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}

const setTimeOnDate = (date: Date, _time: string): Date => {
  const [time, offsetHours] = _time.split(" ").map((val, i) => i === 0 ? val : val === 'PM' ? 12 : 0) as [string, number];
  const [hours, minutes] = time.split(':').map(Number);
  const hourNum = _time !== '12:00 PM' && _time !== '12:30 PM' ? (hours + offsetHours) : hours
  let updatedDate = setHours(date, hourNum);
  updatedDate = setMinutes(updatedDate, minutes);
  return updatedDate;
};

export default TaskForm;