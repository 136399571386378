import { TaskStatus } from "./task-status";

export const taskStatusChipColor = (status: string) => {
  switch (status) {
    case TaskStatus.InProgress:
      return "text-white ring-orange-500 bg-orange-500";
    case TaskStatus.Completed:
      return "text-white ring-success-dark bg-success-dark";
    case TaskStatus.Deferred:
    case TaskStatus.Escalated:
      return "text-white ring-error-dark bg-error-dark";
    case TaskStatus.NotStarted:
    default:
      return "text-white ring-primary-main bg-primary-main";
  }
};
export const taskStatusColor = (status: string) => {
  switch (status) {
    case TaskStatus.InProgress:
      return "text-orange-500";
    case TaskStatus.Completed:
      return "text-success-main";
    case TaskStatus.Deferred:
      return "text-error-main";
    case TaskStatus.NotStarted:
    default:
      return "text-secondary-main";
  }
};
