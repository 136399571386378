const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const _makeSFUsernameSuffix = (brand: BrandConfig) => {
  return `@${brand.domain}`;
};
export const makeSFUsername = (brand: BrandConfig, username: string) => {
  // validate our string
  if (!username) {
    return new Error('A user name is required.');
  }
  const lowercased_username = username.toLowerCase();
  const suffix = _makeSFUsernameSuffix(brand);
  if (EMAIL_REGEX.test(username) || (lowercased_username.endsWith(suffix) && lowercased_username.length > suffix.length)) {
    return username;
  }
  return `${username}${suffix}`;
};
