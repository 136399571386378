import { configureStore } from "@reduxjs/toolkit";
import { compose } from "redux";
import { environment } from "../app/config";
import api from "./api";
import session from "./reducers/session-reducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const Store = configureStore({
  devTools: environment.env !== "production",
  reducer: {
    session,
    api: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

export type AppDispatch = typeof Store.dispatch;
export type RootState = ReturnType<typeof Store.getState>;
export default Store;
