import { salesforce_api } from "../config";
import AuthorizationResponse from "../../classes/auth";

export const refreshUserSFCredentials = async (refreshToken: string) => {
  const path = `${salesforce_api.api_host}/services/oauth2/token?grant_type=refresh_token&client_id=${salesforce_api.client_id}&refresh_token=${refreshToken}`;
  const headers = new Headers();
  headers.set("Content-Type", "application/json");
  headers.set("x-api-key", salesforce_api.x_api_key);

  try {
    const refreshResponse = await fetch(path, {
      method: "POST",
      headers: headers,
    });

    if (refreshResponse.ok) {
      const { id, access_token, issued_at, token_type } =
        await refreshResponse.json();

      const auth = new AuthorizationResponse({
        access_token,
        id,
        token_type,
        refresh_token: refreshToken,
        issued_at,
      });
      return auth;
    }

    console.warn(
      "refresh response is not ok: " +
        refreshResponse.ok +
        ", status: " +
        refreshResponse.status +
        ", statusText: " +
        refreshResponse.statusText
    );
    return false;
  } catch (e: any) {
    console.warn("Failed to update salesforce access token", e);
    return Promise.reject(e);
  }
};
