export function buildSecondaryActions(hasClaim: boolean | undefined, includeMove: boolean = true) {
  //NOTE: We carefully assemble these options in a very specific order (Claim, Defer, Move, Follow-up),
  //      but some of them are optional.
  const baseOptions = [{ title: 'Defer', action: "deferTask", description: 'Defer task to another date.' }];
  if (includeMove) {
    baseOptions.push({ title: 'Move', action: "moveTask", description: 'Move task to another date.' });
  }
  baseOptions.push({ title: 'Follow Up', action: "followUpTask", description: 'Create a follow up task.' });
  if (hasClaim === false) {
    return baseOptions;
  }
  return [{ title: 'Claim', action: 'claimTask', description: 'Claim this task.' }].concat(baseOptions);
}
;
