import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./app/app-router";
import { AuthProvider } from "./app/components/auth/firebase-context";
import { ErrorBoundaryFallback } from "./app/components/error/error-boundary-fallback";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Store from "./store";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Provider store={Store}>
    <BrowserRouter >
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onReset={(e) => {
          console.log('ErrorBoundary reset called!', e);
        }}
      >
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </ErrorBoundary>
    </BrowserRouter >
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
