import { createBedroomsLabel } from './create-bedrooms-label';
import { createBathroomsLabel } from './create-bathrooms-label';

export function createListingDescription(listing: ApiListing) {
  let label = createBedroomsLabel(listing.bedrooms) +
    "・" +
    createBathroomsLabel(listing.bathrooms);
  if (typeof listing.unit_view === 'string' && listing.unit_view !== "") {
    label = label + "・" +
      listing.unit_view;
  }
  return label;
}
;
