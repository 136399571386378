import { doc, getDoc, updateDoc } from "firebase/firestore";
import { taskTypeOptions } from "../../classes/task/task-strings";
import { firestore } from "../config/firebase/app";
import { DB_USERS_KEY } from "../components/auth/firebase-context";

export const postUserSettingsByUsername = async (
  sf_user_id: string,
  settings: Record<string, any>
) => {
  const docRef = doc(firestore, DB_USERS_KEY, sf_user_id);
  if (settings.task_order) {
    ensureCompleteTaskGroups(settings.task_order);
  }
  const documentResponse = await updateDoc(docRef, { user_settings: settings });
  return documentResponse;
};

export const getUserSettingsByUsername = async (sf_user_id: string) => {
  const docRef = doc(firestore, DB_USERS_KEY, sf_user_id);
  const documentResponse = await getDoc(docRef);
  if (documentResponse.exists()) {
    const documentData = documentResponse.data();
    let darkMode = false;
    if (documentData.user_settings) {
      if (documentData.user_settings.task_order) {
        ensureCompleteTaskGroups(documentData.user_settings.task_order);
        return documentData.user_settings;
      }
      // eslint-disable-next-line eqeqeq
      darkMode = documentData.user_settings.darkMode == true; // soft compare intentional
    }

    // this user has bad settings, reset them
    const defaultOrder: string[] = [];
    ensureCompleteTaskGroups(defaultOrder);
    const repairedSettings = { darkMode, task_order: defaultOrder };
    await postUserSettingsByUsername(sf_user_id, repairedSettings);
    return repairedSettings;
  }
  return null;
};

export function ensureCompleteTaskGroups(order: string[]) {
  const missing = taskTypeOptions.filter((item) => !order.includes(item));
  if (missing.length <= 0) {
    return;
  }
  // console.warn('appending missing task types to user settings', missing);
  order.push(...missing);
}
