import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import useMakeSFRequest from "./request/salesforce/make-sf-call";
import brand from "./config/brand";
import { useAuth } from "./components/auth/firebase-context";

const cacheDefaultState = {
  listings: { loading: false, success: false, error: null as string | null, initialized: false, data: [] as ApiListing[] },
  users: { loading: false, success: false, error: null as string | null, initialized: false, data: [] as ApiUser[] },
  myQueues: { loading: false, success: false, error: null as string | null, initialized: false, data: [] as ApiQueue[] },
  allQueues: { loading: false, success: false, error: null as string | null, initialized: false, data: [] as ApiQueue[] },
  memberOfBrand: true,
  refreshListings: async () => { },
  refreshUser: async () => { },
  refreshMyQueues: async () => { },
  refreshAllQueues: async () => { },
}
const CacheContext = createContext(cacheDefaultState);
export const CacheProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const request = useMakeSFRequest();
  const { user } = useAuth();

  // Listings
  const [listingsState, setListingsState] = useState<typeof cacheDefaultState['listings']>(cacheDefaultState.listings);
  const requestActiveListings = useCallback(async () => {
    setListingsState((prev) => ({ ...prev, initialized: true, loading: true, error: null }));
    const endpointPath = `/api/ohana/listing?action=activeListings`;
    const response = await request({ endpointPath, method: 'GET' });
    if (response.error) {
      setListingsState((prev) => ({ ...prev, loading: false, error: 'There was a problem requesting listings.' }));
      return;
    }
    setListingsState((prev) => ({
      ...prev,
      success: true,
      loading: false,
      error: null,
      data: (response as any).listings.sort((a: ApiListing, b: ApiListing) => {
        if (!a?.unit_id) {
          return -1
        }
        if (!b?.unit_id) {
          return 1
        }
        return a.unit_id.localeCompare(b.unit_id)
      })
    } as typeof prev));
  }, [request]);

  const [memberOfBrandState, setMemberOfBrandState] = useState<boolean>(cacheDefaultState.memberOfBrand);

  // Active users
  const [usersState, setUsersState] = useState<typeof cacheDefaultState['users']>(cacheDefaultState.users);
  const requestActiveUsers = useCallback(async () => {
    setUsersState((prev) => ({ ...prev, initialized: true, loading: true, error: null }));
    const activeUsersEndpoint = `/api/user?action=activeUsersForBrand&brandCode=${brand.code}`;

    const activeUsersResponse = await request({ endpointPath: activeUsersEndpoint, method: 'GET' });
    if (activeUsersResponse.error) {
      setUsersState((prev) => ({ data: prev.data, initialized: true, success: false, loading: false, error: 'There was a problem requesting all users.' }));
      return;
    }

    // check whether the session user belongs to this site's brand group
    const activeUsers = (activeUsersResponse as any).users;
    if (Array.isArray(activeUsers) && activeUsers.length > 0) {
      let sessionUserFoundInThisBrand = false;
      // for (const [k, v] of activeUsers) {
      for (let i = 0; i < activeUsers.length; ++i) {
        const v = activeUsers[i];
        if (user?.id === v.id) {
          sessionUserFoundInThisBrand = true;
          break;
        }
      }
      if (!sessionUserFoundInThisBrand) {
        setMemberOfBrandState(false);
      }
    }

    setUsersState((prev) => ({ initialized: true, success: true, loading: false, error: null, data: activeUsers }));
  }, [request]);

  // All queues
  const [allQueuesState, setAllQueuesState] = useState<typeof cacheDefaultState['allQueues']>(cacheDefaultState.allQueues);
  const requestAllQueues = useCallback(async () => {
    setAllQueuesState((prev) => ({ ...prev, initialized: true, loading: true, error: null }));
    const allQueuesEndpoint = `/api/group?action=allQueues`;
    const queueResponse = await request({ endpointPath: allQueuesEndpoint, method: 'GET' });
    if (queueResponse.error) {
      setAllQueuesState((prev) => ({ data: prev.data, initialized: true, success: false, loading: false, error: 'There was a problem requesting all groups.' }));
      return;
    }
    setAllQueuesState((prev) => ({ initialized: true, success: true, loading: false, error: null, data: (queueResponse as any).queues }));
  }, [request])

  // My queues
  const [myQueuesState, setMyQueuesState] = useState<typeof cacheDefaultState['myQueues']>(cacheDefaultState.myQueues);
  const requestMyQueues = useCallback(async () => {
    setMyQueuesState((prev) => ({ ...prev, initialized: true, loading: true, error: null }));
    const myQueues = `/api/group?action=myQueues`;
    const queueResponse = await request({ endpointPath: myQueues, method: 'GET' });
    if (queueResponse.error) {
      setMyQueuesState((prev) => ({ data: prev.data, initialized: true, success: false, loading: false, error: 'There was a problem requesting my groups.' }));
      return;
    }
    setMyQueuesState((prev) => ({ initialized: true, success: true, loading: false, error: null, data: (queueResponse as any).queues }));
  }, [request])


  // Perform request
  useEffect(() => {
    Promise.all([
      requestActiveListings(),
      requestActiveUsers(),
      requestAllQueues(),
      requestMyQueues()
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => ({
    listings: listingsState,
    allQueues: allQueuesState,
    myQueues: myQueuesState,
    users: usersState,
    memberOfBrand: memberOfBrandState,
    refreshListings: requestActiveListings,
    refreshUser: requestActiveUsers,
    refreshMyQueues: requestMyQueues,
    refreshAllQueues: requestAllQueues
  }), [
    listingsState,
    allQueuesState,
    myQueuesState,
    usersState,
    memberOfBrandState,
  requestActiveListings,
    requestActiveUsers,
    requestMyQueues,
    requestAllQueues
  ]);
  return <CacheContext.Provider value={value}>{children}</CacheContext.Provider>;
};

export const useCache = () => {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error('Hook must be called within CacheContext.Provider.');
  }
  return context;
};