import { format, isThisWeek, isToday, isTomorrow, isYesterday } from "date-fns";
import { ApiTask } from "../types/task";

export const createDateLabel = (
  d: Date | string,
  defaultFormat: string = "eeee"
) => {
  let date = d as Date;
  if (typeof d === "string") {
    date = new Date(d);
  }
  return isYesterday(date)
    ? "Yesterday"
    : isToday(date)
    ? "Today"
    : isTomorrow(date)
    ? "Tomorrow"
    : format(date, defaultFormat);
};

export const createWeekLabel = (
  d: Date | string,
  defaultFormat: string = "eeee"
) => {
  let date = d as Date;
  if (typeof d === "string") {
    date = new Date(d);
  }
  return isThisWeek(date) ? "This Week" : format(date, defaultFormat);
};

export const createDateLabelForTask = (task: ApiTask, breakpoint?: string) => {
  const { related, name } = task;
  const useFullLabel =
    breakpoint && ["md", "lg", "xl", "2xl"].includes(breakpoint);
  const dateLableDefaultFormat = useFullLabel ? "eeee, MMM do" : "eeee";
  const parts = [];
  if (related?.unit_id) {
    parts.push(related.unit_id);
  }
  if (name) {
    parts.push(name);
  }
  if (task.due_datetime) {
    if (isThisWeek(new Date(task.due_datetime))) {
      parts.push(
        createDateLabel(task.due_datetime, dateLableDefaultFormat) +
          ` @ ${format(new Date(task.due_datetime), "h:mm bb")}`
      );
    } else {
      parts.push(
        format(
          new Date(task.due_datetime),
          (useFullLabel ? "eeee, MMM do " : "eee, MMM do") + " @ h:mm bb"
        )
      );
    }
  }
  return parts.join("・");
};
