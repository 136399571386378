export const convertBillable = (billable: string) => {
  const numberValue = parseFloat(billable);

  // we enforce 15-minute increments for billable hours
  const fourXInt = Math.floor(numberValue * 4);
  const backToFloat = fourXInt / 4;

  if (!isNaN(backToFloat)) {
    const formattedValue = backToFloat.toFixed(2);
    return formattedValue;
  }
  return "0";
};

export const convertCurrency = (currency: string) => {
  const numberValue = extractFloatFromCurrencyText(currency);
  if (!isNaN(numberValue) && numberValue >= 0) {
    const formattedValue = numberValue.toFixed(2);
    return `$${formattedValue}`;
  }
  return "$0.00";
};

export const extractFloatFromCurrencyText = (currencyText: string) => {
  const noDollarSign = currencyText.replace("$", "");
  const parsed = parseFloat(noDollarSign);
  return parsed;
};

export const handleBillableBlur = (e: any) => {
  return convertBillable(e.target.value);
};

export const handleCurrencyBlur = (e: any) => {
  return convertCurrency(e.target.value);
};

export const handleFloatInputChange = (e: any) => {
  const inputValue = e.target.value.replace(/[^0-9.]/g, ""); // Allow only numbers and period
  return inputValue;
};
