import { Combobox as HeadlessCombobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import clsx from 'clsx'
import React, { useState } from 'react'
/**@deprecated replace with ChipListInput */
const Combobox: React.FC<{
  id: string,
  options: { id: string, label: string, group?: string }[],
  label: string,
  labelClassName?: string,
  value: string | string[] | null,
  setValue(a: string | string[] | null): void,
  defaultOption: { id: string, label: string, group?: string },
  disabled?: boolean,
  groups?: string[],
  multiple?: boolean
}> = ({ id, options, label, labelClassName, value, setValue, defaultOption, disabled, groups, multiple }) => {
  const [query, setQuery] = useState('')
  const selectedOption = multiple ? options.filter((option) => value?.includes(option.id) || value?.includes(option.label)) : options.find((option) => option.id === value || option.label === value) || defaultOption
  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.label.toLowerCase().includes(query.toLowerCase())
      })
  return (
    <HeadlessCombobox
      id={id}
      as="div"
      multiple={multiple}
      disabled={disabled}
      value={Array.isArray(selectedOption) ? selectedOption.map((opt) => opt.id) : selectedOption.id}
      onChange={(option) => {
        setQuery('')
        setValue(option)
      }}
    >
      <Label className={clsx("block text-sm font-medium leading-6 text-gray-900", labelClassName)}>{label}</Label>
      <div className="relative mt-2">
        <ComboboxInput
          className="disabled:text-gray-500 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-main sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          displayValue={() => Array.isArray(selectedOption) ? selectedOption.map((opt) => opt.label).join(', ') : selectedOption.label}
        />
        {!disabled &&
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <span className="icon-[iconamoon--arrow-down-2] w-5 h-5 text-gray-500" />
          </ComboboxButton>
        }

        {filteredOptions.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {groups && groups?.map((groupLabel, index) =>
              <React.Fragment key={`${groupLabel}-${index}-option`}>
                <span className='flex items-center pl-1 bg-gray-100 text-center leading-6 font-semibold text-xl relative h-9'>{groupLabel}</span>
                {filteredOptions.filter((option) => option.group === groupLabel).map((option, optionIndex) => (
                  <ComboboxOption
                    key={`${option.id}-${optionIndex}-${label}`}
                    value={option.id}
                    className={({ focus }) =>
                      clsx(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        focus ? 'bg-primary-main text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ focus, selected }) => (
                      <>
                        <span className={clsx('block truncate', selected && 'font-semibold')}>{option.label}</span>

                        {selected && (
                          <span
                            className={clsx(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              focus ? 'text-white' : 'text-primary-main'
                            )}
                          >
                            <span className="icon-[grommet-icons--checkbox-selected] w-5 h-5" aria-hidden='true' />
                          </span>
                        )}
                      </>
                    )}
                  </ComboboxOption>
                ))}
              </React.Fragment>)}
            {!groups && filteredOptions.map((option, optionIndex) => (
              <ComboboxOption
                key={`${option.id}-${optionIndex}-${label}`}
                value={option.id}
                className={({ focus }) =>
                  clsx(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    focus ? 'bg-primary-main text-white' : 'text-gray-900'
                  )
                }
              >
                {({ focus, selected }) => (
                  <>
                    <span className={clsx('block truncate', selected && 'font-semibold')}>{option.label}</span>

                    {selected && (
                      <span
                        className={clsx(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          focus ? 'text-white' : 'text-primary-main'
                        )}
                      >
                        <span className="icon-[grommet-icons--checkbox-selected] w-5 h-5" aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>)}
      </div>
    </HeadlessCombobox>
  )
}

export default Combobox;