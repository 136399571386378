import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import clsx from 'clsx';
import React, { PropsWithChildren, ReactNode } from 'react';

type ModalProps = PropsWithChildren<{
  id?: string,
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  title?: string,
  subTitle?: string,
  renderButton?: ReactNode,
  cancelButtonText?: string,
  buttonClassName?: string,
  hideButtons?: boolean,
  alwaysShowX?: boolean
}>

const Modal: React.FC<ModalProps> = ({ id, open, setOpen, title, subTitle, renderButton, cancelButtonText, children, buttonClassName, hideButtons = false, alwaysShowX = true }) => {

  return (
    <Transition show={open}>
      <Dialog id={id} className="relative z-[100]" onClose={setOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                {!alwaysShowX && <br className={clsx(!alwaysShowX && 'sm:hidden')} />}
                {alwaysShowX && <div className={clsx('absolute top-2 right-2', !alwaysShowX && 'sm:hidden')}>
                  <button
                    type='button'
                    onClick={() => { setOpen(false); }}
                    className='text-primary-main hover:text-secondary-main dark:text-white'>
                    <span className="icon-[material-symbols--close] w-6 h-6 text-gray-500 hover:text-gray-900" />
                  </button>
                </div>}
                {title && <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </DialogTitle>
                    {subTitle && <p className="text-sm text-gray-500">
                      {subTitle}
                    </p>}
                  </div>
                </div>}
                <div className={clsx(title && 'mt-4', 'w-full')}>
                  {children}
                </div>
                {!hideButtons && <div className={clsx("mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3", buttonClassName)}>
                  <div className='col-span-1'>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      data-autofocus
                    >
                      {cancelButtonText || 'Cancel'}
                    </button>
                  </div>
                  <div className='col-span-1'>
                    {renderButton !== undefined && renderButton}
                  </div>
                </div>}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};

export default Modal;